import * as R from 'rambdax'
import * as regexp from './regexp'
import {Falsy, notNullish, Nullish, nullish, truthy} from './types'

const r = (flags: string) => (patt: string) => regexp.compile(patt, flags + 'u')
const g = r('g')

export const escapeColons: (s: string) => string = R.pipe(
  R.replace(g('%'), '%25'),
  R.replace(g(':'), '%3A'),
)

export const colonJoin: (arr: string[]) => string = R.pipe(
  R.map(x => `${x}`),
  R.map(escapeColons),
  R.join(':'),
)

export const escapeSemis: (s: string) => string = R.replace(
  g('[;\\\\]'),
  '\\$&',
)

export const semiJoin: (arr: string[]) => string = R.pipe(
  R.map(x => `${x}`), // make sure
  R.reject(R.isEmpty),
  R.map(escapeSemis),
  R.join(';'),
)

export const semiSplit: (s: string | Nullish) => string[] = s =>
  R.piped(
    s || '',
    R.match(g('(?:\\\\.|[^;])+')),
    R.map(s => s.replace(/\\(.)/g, m => m[1])),
    R.reject(R.isEmpty),
  )

export const adminTagged = (s: string) =>
  semiSplit(s)
    .map(s => s.trim())
    .filter(Boolean)
    .map(s => colonJoin(['AdminTagged', 'AdminTags', s]))

export const toLongId = (tizraId: string) => parseInt(tizraId, 32)

export const toTizraId = (longId: number) => longId.toString(32)

/**
 * Return an array, no matter what.
 */
export const ensureArray = <T>(x: T[] | T | Nullish): T[] =>
  Array.isArray(x) ? x
  : nullish(x) ? []
  : [x]

/**
 * Return a not-nullish (or empty) array, no matter what.
 *
 * This is the same as ensureArray(x).filter(notNullish) except that it does
 * some extra type manipulation to avoid returning a union of arrays.
 */
export const notNullishArray = <T>(
  x: T | Nullish | Array<T | Nullish>,
): T[] => {
  // https://stackoverflow.com/a/70763406/347386
  const values: (T | Nullish)[] = ensureArray(x)
  return values.filter(notNullish)
}

/**
 * Return a truthy (or empty) array, no matter what.
 *
 * This is the same as ensureArray(x).filter(truthy) except that it does some
 * extra type manipulation to avoid returning a union of arrays.
 */
export const truthyArray = <T>(x: T | Falsy | Array<T | Falsy>): T[] => {
  // https://stackoverflow.com/a/70763406/347386
  const values: (T | Falsy)[] = ensureArray(x)
  return values.filter(truthy)
}

export const delay = (ms?: number) =>
  new Promise<void>(resolve =>
    typeof window === 'undefined' || !ms ?
      resolve()
    : window.setTimeout(resolve, ms),
  )

export const parseBool = (x: unknown) => {
  const s = `${x}`.toLowerCase()
  return (
    ['true', 'on', 'yes', '1'].includes(s) ? true
    : ['false', 'off', 'no', '0'].includes(s) ? false
    : undefined
  )
}

/**
 * Convert array or object to query string, with keys/values properly
 * escaped. For objects, sorts the keys. Values can be arrays, in which
 * case the keys will be repeated in the query string.
 */
type QSValue = boolean | number | string
export type QSPairs =
  | Array<[string, QSValue | QSValue[]]>
  | Record<string, QSValue | QSValue[]>
export function toQueryString(oPairs: QSPairs, prefix = ''): string {
  const qs = new URLSearchParams()

  // When passed an object of key/value pairs, sort the entries to get
  // consistent API calls.
  const pairs =
    Array.isArray(oPairs) ? oPairs : R.sortBy(R.prop(0), R.toPairs(oPairs))

  // Each value should be either bool/str/num or array of such.
  for (let [key, value] of pairs) {
    const values = Array.isArray(value) ? value : [value]
    for (const v of values) {
      qs.append(key, typeof v === 'string' ? v : `${v}`)
    }
  }

  const s = qs.toString()
  return s && prefix ? `${prefix}${s}` : s
}

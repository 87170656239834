/**
 * Debugging flag for Evergreen apps. This enables copious browser console
 * logging along with on-page debug tools.
 *
 * Set for current page with ?debug or tizra.setDebug(true) in browser console.
 * Set for session with ?debug=session or tizra.setDebug('session')
 * Reset with ?debug=off or tizra.setDebug(false)
 */

import {IS_TEST} from './globals'
import {parseBool} from './utils'
import * as storage from './storage'

const QUERY_KEY = 'debug'
const LOCAL_STORAGE_KEY = 'tizraDebugEnabled'

let globalDebugEnabled: boolean | 'session'

const parseDebug = (v: unknown): boolean | 'session' | undefined =>
  v === 'session' ? v : parseBool(v)

export function setDebug(setEnabled?: unknown) {
  let enabled = parseDebug(setEnabled) ?? parseDebug(getQuery(QUERY_KEY))
  if (enabled === 'session') {
    storage.put(LOCAL_STORAGE_KEY, enabled)
  } else if (enabled === false) {
    storage.remove(LOCAL_STORAGE_KEY)
  } else if (storage.get(LOCAL_STORAGE_KEY) === 'session') {
    enabled = 'session'
  }
  globalDebugEnabled = enabled ?? globalDebugEnabled ?? IS_TEST
  return globalDebugEnabled
}

function getQuery(key: string) {
  if (typeof window !== 'undefined') {
    const value = new URL(window.location.href).searchParams.get(key)
    return value === '' ? 'true' : value // ?debug is true
  }
}

export function debugging() {
  return globalDebugEnabled
}

setDebug()

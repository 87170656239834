import {logger} from 'tizra/log'
import {FontSuffix, FontVars, Scale, Weight, cssVars} from '../vars'
import {Branding, FontName} from './branding'

const log = logger('theme/fonts')

export const fontKeys = ['body', 'heading', 'ui'] as const

export type FontKey = (typeof fontKeys)[number]

interface FontSpec {
  fallbacks: readonly string[]
  weights: {[k in Weight]: number}
  scales: {[k in Scale]: number}
  ui?: FontName
}

export const fontSpecs: {[k in FontName]: FontSpec} = {
  Merriweather: {
    fallbacks: ['serif'],
    weights: {light: 300, regular: 300, bold: 700, black: 900},
    scales: {size: 1, height: 1, margin: 1},
    ui: 'Merriweather Sans',
  },
  'Merriweather Sans': {
    fallbacks: ['sans-serif'],
    weights: {light: 300, regular: 300, bold: 600, black: 800},
    scales: {size: 1, height: 1, margin: 1},
  },
  'Open Sans': {
    fallbacks: ['sans-serif'],
    weights: {light: 300, regular: 400, bold: 600, black: 800},
    scales: {size: 1, height: 1, margin: 1},
  },
}

const deriveUiFont = (..._names: Array<FontName | undefined>): FontName => {
  const names = _names.filter(name => name && name in fontSpecs) as FontName[]
  for (const name of names) {
    if (!fontSpecs[name].ui) {
      return name
    }
  }
  for (const name of names) {
    const {ui} = fontSpecs[name]
    if (ui) return ui
  }
  log.error('deriveUiFont fail', _names)
  return 'Open Sans'
}

const makeFontMap = <K extends FontKey>(key: K, name: FontName) => {
  const {fallbacks, weights, scales} = fontSpecs[name]
  return {
    [`--eg-${key}-ff`]: [name, ...fallbacks].join(', '),
    [`--eg-${key}-fwl`]: `${weights.light}`,
    [`--eg-${key}-fwr`]: `${weights.regular}`,
    [`--eg-${key}-fwb`]: `${weights.bold}`,
    [`--eg-${key}-fwbb`]: `${weights.black}`,
    [`--eg-${key}-fss`]: `${scales.size}`,
    [`--eg-${key}-fsh`]: `${scales.height}`,
    [`--eg-${key}-fsm`]: `${scales.margin}`,
  } as {[s in FontSuffix as `--eg-${K}-${s}`]: string}
}

// Map body/heading/ui to per-font vars.
// Add font-family for convenience to set vars and select font at once.
export const fonts = fontKeys.reduce(
  (acc, k) => {
    acc[k] = {
      [cssVars.fontFamily]: `var(--eg-${k}-ff)`,
      [cssVars.weights.light]: `var(--eg-${k}-fwl)`,
      [cssVars.weights.regular]: `var(--eg-${k}-fwr)`,
      [cssVars.weights.bold]: `var(--eg-${k}-fwb)`,
      [cssVars.weights.black]: `var(--eg-${k}-fwbb)`,
      [cssVars.scales.size]: `var(--eg-${k}-fss)`,
      [cssVars.scales.height]: `var(--eg-${k}-fsh)`,
      [cssVars.scales.margin]: `var(--eg-${k}-fsm)`,
      fontFamily: `var(${cssVars.fontFamily})`,
    }
    return acc
  },
  {} as {[k in FontKey]: FontVars & {fontFamily: string}},
)

// compat
const fontWeights = Object.fromEntries(
  Object.entries(cssVars.weights).map(([w, v]) => [w, `var(${v})`]),
) as {[k in keyof typeof cssVars.weights]: string}

export const getFonts = (brand: Branding['fonts']) => {
  // Map font keys (body/heading/ui) to font names.
  const baseFonts: {[k in FontKey]: FontName} = {
    ...brand,
    ui: deriveUiFont(brand.body, brand.heading),
  }

  const fontMap = {
    ...makeFontMap('body', baseFonts['body']),
    ...makeFontMap('heading', baseFonts['heading']),
    ...makeFontMap('ui', baseFonts['ui']),
  } satisfies {[k in FontKey as `--eg-${k}-${FontSuffix}`]: string}

  return {baseFonts, fontMap, fonts, fontWeights}
}

import {DefaultOptions} from '@tanstack/react-query'
import {IS_SSR} from '../globals'
import {logger} from '../log'

export const log = logger('tizra/react-query')

export const gcTime = IS_SSR ? Infinity : 24 * 60 * 60 * 1000 // 24 hours

export const defaultOptions: DefaultOptions = {
  queries: {
    // react-query will retry failed API calls by default. This behavior
    // only really makes sense in large systems where the API server farm
    // might have troubled nodes or some kind of non-deterministic fast
    // failures. For the Tizra server, a failure is unlikely to become
    // a success just by calling back.
    retry: false,

    // Explicitly set gcTime because it should match persister maxAge.
    gcTime,

    // The only time we want refetch for stale queries is when the component
    // mounts (i.e. the page loads) or when the result is explicitly
    // invalidated.
    refetchInterval: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  },
}

import {createContext, ReactNode, useContext, useState} from 'react'
import {logger} from 'tizra'
import {useIsomorphicLayoutEffect} from './useIsomorphicLayoutEffect'

const log = logger('useHydration')

const HydrationContext = createContext<boolean | null>(null)

export const HydrationProvider = ({
  children,
  hydrate,
}: {
  children: ReactNode
  hydrate: boolean
}) => {
  const [hydrating, setHydrating] = useState<boolean>(hydrate)
  useIsomorphicLayoutEffect(() => setHydrating(false), [setHydrating])
  return (
    <HydrationContext.Provider value={hydrating}>
      {children}
    </HydrationContext.Provider>
  )
}

/**
 * Ensure consistency between SSR and initial render on client, so that
 * hydration works. See
 * https://github.com/smooth-code/xstyled/pull/114#issuecomment-690475156
 */
export const useHydrating = (): boolean => {
  const hydrating = useContext(HydrationContext)
  if (hydrating === null) {
    log.error('useHydrating called outside of HydrationProvider')
  }
  return !!hydrating
}

export const useHydration = <T, U>(before: T, after: U): T | U => {
  return useHydrating() ? before : after
}

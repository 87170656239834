import {deepMerge} from 'tizra/deepMerge'
import {PartialDeep} from 'type-fest'

export type FontName = 'Merriweather' | 'Merriweather Sans' | 'Open Sans'

export interface Branding {
  colors: {
    button: string
    link: string
  }
  fonts: {
    body: FontName
    heading: FontName
  }
}

export const defaultBranding: Branding = {
  colors: {
    button: '#222955',
    link: '#4c779e',
  },
  fonts: {
    body: 'Merriweather Sans',
    heading: 'Merriweather Sans',
  },
}

export const getBranding = (branding?: PartialDeep<Branding>) =>
  deepMerge(defaultBranding, {
    pred: v => v !== undefined && (typeof v !== 'string' || v.trim() !== ''),
  })(branding)

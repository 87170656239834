import {JsonValue} from 'type-fest'

type NA<T> = Array<T | null> | null

export type ParsedProps<T = object> = T & Record<string, JsonValue>

export interface GenericMetaObject<
  P extends GenericMetaObject | never = GenericMetaObject<never>,
> {
  isFree: boolean
  metaType: string
  name: string
  tizraId: string
  urlId: string
  pdfSourceFileName?: string
  props: ParsedProps<{
    BypassToC?: boolean
    hasOEmbed?: boolean
    CoverImage?: string | ParamFile
  }>
  // Not from the API, except on Excerpt. Attached by useMetaObj as needed.
  parent?: P
}

type UnknownMetaObject = undefined | {metaType: string}

export const isCollectionMetaType = (metaType: string) =>
  metaType.includes('Collection')

export const isCollection = (x: UnknownMetaObject): x is GenericMetaObject =>
  x !== undefined && isCollectionMetaType(x.metaType)

export type Excerpt<P extends GenericMetaObject = GenericMetaObject> =
  GenericMetaObject & {
    metaType: 'PageRange'
    pages: [number, number]
    // Excerpts always include the parent object, whether from /api/query or
    // /api/search. There is no bookTizraId on an Excerpt, so there's no way to
    // find the parent if it were omitted.
    parent: P
  }

export const isExcerptMetaType = (metaType: string) => metaType === 'PageRange'

export const isExcerpt = (x: UnknownMetaObject): x is Excerpt =>
  x !== undefined && isExcerptMetaType(x.metaType)

export interface PdfPage extends GenericMetaObject {
  metaType: 'PdfPage'
  bookTizraId: string
  logicalPageNumber: string
  pageNumber: number
}

export const isPdfPage = (x: UnknownMetaObject): x is PdfPage =>
  x?.metaType === 'PdfPage'

export interface User extends GenericMetaObject {
  email: string
  isAdmin: boolean
  metaType: 'UserData'
  name: string
}

export interface TocEntry<P extends GenericMetaObject = GenericMetaObject> {
  id: string
  bookTizraId: string
  metaType: 'toc-entry'
  logicalPage: string
  pageNumber: number
  title: string
  level: number
  isDisplayed: boolean
  // Not from the API. Attached by useMetaObj as needed.
  parent?: P
}

export interface NestedTocEntry<P extends GenericMetaObject = GenericMetaObject>
  extends TocEntry<P> {
  hasChildren: boolean
  children: NestedTocEntry<P>[]
}

export const isTocEntry = (x: UnknownMetaObject): x is TocEntry =>
  x?.metaType === 'toc-entry'

export interface Termsheet {
  duration: string
  endDate: string | null
  excludedViews: string[]
  isExpired: boolean
  startDate: string | null
}

export interface License {
  active: boolean
  controlled: string
  creationDate: string
  id: number
  terms: Termsheet
}

export interface Licenses {
  allUserSets?: NA<{
    licenses?: NA<License>
  }>
  licenseBindings?: NA<{
    licenses?: NA<License>
  }>
  samplingLicenses?: NA<unknown>
  sessionLicenses?: Record<string, License | null>
  userLicenses?: NA<{
    user?: User | null
    userLicenseInfo?: null | {
      userlicenses?: NA<License>
    }
  }>
}

export interface Offer extends GenericMetaObject {
  controlled: {
    metaType: string
    tizraId: string
  }
  currencyInfo: string
  metaType: 'Offer'
  price: number
  termSheet: {
    duration: string
    endDate: string | null
    excludedViews: string[]
    isExpired: boolean
    startDate: string | null
  }
}

export type MetaObject = Excerpt | Offer | PdfPage | User | GenericMetaObject

export type SearchResultTypes = MetaObject | TocEntry

export type SearchResult<T = SearchResultTypes> = T & {
  snippet?: string
}

export interface CartItem {
  item: MetaObject
  offer: Offer
}

export interface CheckoutMeta {
  checkoutMethods: CheckoutMetaMethod[]
}

export interface CheckoutMetaMethod {
  displayName: string
  name: string
  steps: CheckoutMetaStep[]
}

export interface CheckoutMetaStep {
  step: string
  displayName: string
  final?: boolean
  optional?: boolean
}

export interface CheckoutMethod {
  firstSteps: Record<string, CheckoutStep>
}

export interface CheckoutStep {
  buttonPrompt?: string
  displayName?: string
  final?: boolean
  infoPrompt?: string
  infoRequired?: Record<string, CheckoutField>
  nextSteps?: string[]
}

export interface CheckoutField {
  prompt?: string
  type?: 'string' | string
  validationType?: string
  optional?: boolean
  required?: boolean
}

export interface ReasonableError {
  reason: string
  message: string
}

export interface FormErrors extends Partial<ReasonableError> {
  errors?: Record<string, ReasonableError>
}

export type PropType =
  | 'auto-uuid'
  | 'boolean-list' // keyword checklist
  | 'css-color'
  | 'date'
  | 'date-list'
  | 'float'
  | 'float-list'
  | 'html'
  | 'integer'
  | 'integer-list'
  | 'ISBN'
  | 'ISBN-13'
  | 'json-array'
  | 'json-hash'
  | 'json-value'
  | 'keyword'
  | 'keyword-list'
  | 'markdown'
  | 'param-file'
  | 'reference'
  | 'reference-list'
  | 'string'
  | 'string-list'
  | 'text'

export interface PropDef {
  displayName: string
  inheritValue: boolean
  isCalculated: boolean
  isConstrained: boolean
  isJsonVisible: boolean
  isSearchable: boolean
  isSystem: boolean
  isUserDefined: boolean
  isUserVisible: boolean
  lang: unknown
  metaTagExternalName: string
  name: string
  sortField: string
  type: PropType
  valueSpecification: string
}

export interface SearchType {
  allSubtypes: string[]
  baseType: string
  baseTypeAncestry: string[]
  displayName: string
  displayNamePlural: string
  displayNameSingular: string
  id: number
  isInternal: boolean
  isSystemDefinition: boolean
  isUserDefined: boolean
  metaSourceNames: string[]
  name: string
  namePropName: string
  propDefsIncludingSubtypes: {[k: string]: PropDef}
  subtypes: string[]
  supertypes: string[]
  tagDefinitionsIncludingSubtypes: {[k: string]: PropDef}
}

export type SearchTypes = {[k: string]: SearchType}

export interface Attachment {
  contentType: string
  dateUploaded?: string
  name: string
  numberOfPages?: number
  props: ParsedProps<{
    DisplayName?: string
    includeDirectly?: boolean
    isDownload?: boolean
    isVisible?: boolean
    isUrlName?: boolean
    target?: string
  }>
  size?: number
  sortField?: string
  sourceName: string
  url: string
}

type ParamFile = Attachment | {default: string}

export type Paged<T> = {
  result: T[]
  size: number
  next?: string
}

export interface AuthInfo {
  authInfo: string
  objectDefaultViewConfig: {
    free: string[]
    restricted: string[]
  }
  sessionViewConfig: {
    free: string[]
    restricted: string[]
  }
}

export type OEmbed = {
  html?: string
  thumbnail_url_with_play_button?: string
  thumbnail_url?: string
}

export type Redemption = {
  licensesAdded?: License[]
  sessionLicensesAdded?: License[]
  tagsAdded?: string[]
  user: User
}

export type PubInfo = {
  detailUrl: string
  numPages: number
  title: string
  tizraId: string
  urlId: string
}

export type PdfPageInfo = {
  imageUrl: string // "/9sh/cdn-2023-04-24 14:36:00.236/joy/1.jpg",
  xmlUrl: never
  // I don't trust this API, so everything after is optional
  attachmentsUrl?: string // "/9sh/cdn-2023-04-24 14:36:00.236/joy/1.attachments-list"
  extractedTextUrl?: string // "/9sh/cdn-2023-04-24 14:36:00.236/joy/1.extracted-text",
  imageLinks?: Array<{
    anchorText?: string | null
    destination: string
    destinationType:
      | 'anchor_ref'
      | 'attachment_ref'
      | 'logical_page'
      | 'old_style'
      | 'page_ref'
      | 'print_page'
      | 'script'
      | 'url_ref'
    htmlClass?: string | null
    linkRel?: string | null
    linkTitle?: string | null
    pageNum?: number | null // for destinationType page_ref
    rectangleList: Array<{
      top: number
      left: number
      height: number
      width: number
    }>
    target?: string | null
  }>
  searchHighlightUrl?: string // "/api/highlighted-value?tizra-id=btc&page-num=1"
}

export type XmlPageInfo = {
  imageUrl: never
  xmlUrl: string // "/9sh/cdn-2023-03-07 19:32:31.254/k9dd/1.xml"
  // I don't trust this API, so everything after is optional
  attachmentsUrl?: string // "/9sh/cdn-2023-04-24 14:36:00.236/joy/1.attachments-list"
}

export type PageInfo = PdfPageInfo | XmlPageInfo

export interface AdminMetaObject extends GenericMetaObject {
  hasUnpublishedChanges: boolean
  liveDate: string
  modifiedDate: string
  publishedLive: boolean
  publishedStaging: boolean
  stagingDate: string
}

export interface AdminType {
  allPropName: string[]
  allSubtypes: string[]
  baseType: string
  baseTypeAncestry: string[]
  displayName: string
  displayNamePlural: string
  displayNameSingular: string
  id: number
  isInternal: boolean
  isSystemDefinition: boolean
  isUserDefined: boolean
  metaSourceNames: string[]
  name: string
  namePropName: string
  propDefs: {[k: string]: string}
  subtypes: string[]
  superTypeAncestry: string[]
  supertypes: string[]
}

export type Nullish = null | undefined

export const nullish = (x: any): x is Nullish => x === null || x === undefined

export const notNullish = <T>(x: T | Nullish): x is T => !nullish(x)

export type Falsish = Nullish | false

export const falsish = (x: any): x is Falsish => nullish(x) || x === false

export const truish = <T>(x: T | Falsish): x is T => !falsish(x)

export type Falsy = Falsish | 0 | ''

export const falsy = (x: any): x is Falsy => !x

export const truthy = <T>(x: T | Falsy): x is T => !falsy(x)

export const isObjectType = (x: unknown): x is object =>
  typeof x === 'object' && x !== null

const isObject = (x: unknown) =>
  Object.prototype.toString.call(x) === '[object Object]'

export const isPlainObject = (
  x: unknown,
): x is Record<string | symbol, unknown> => {
  // https://github.com/jonschlinkert/is-plain-object/blob/master/is-plain-object.js
  if (!isObject(x)) return false
  const ctor = (x as any).constructor
  if (ctor === null || ctor === undefined) return true
  const proto = (ctor as any).prototype
  if (!isObject(proto)) return false
  if (!Object.hasOwn(proto, 'isPrototypeOf')) return false
  return true
}

export const isThenable = <T>(x: unknown): x is Promise<T> =>
  typeof (x as any)?.then === 'function'

/**
 * Identity function that kills TS object spread complaints.
 */
export const butter = <T>(x: boolean | null | undefined | T) => x as T

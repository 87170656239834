import {toRem} from './utils'

export const space = {
  xxs: toRem(6),
  xs: toRem(8),
  sm: toRem(10),
  md: toRem(12),
  lg: toRem(16),
  xl: toRem(24),
  xxl: toRem(32),
  xxxl: toRem(48),
  borderedBoxPaddingSm: toRem(20),
} as const

export const gaps = {
  sideNav: '30px',
}

export const borderWidths = {
  sm: '1px',
  md: '2px',
} as const

export const shadows = {
  sm: '1px 2px 4px 0 rgba(0,0,0,0.05)',
  v2: '0 0 .9375rem 0.3125rem rgba(0,0,0,.1)',
  bottom: '0 1.3px 2.5px rgba(0, 0, 0, 0.075), 0 10px 20px rgba(0, 0, 0, 0.15)',
} as const

export const transitions = {
  slow: '500ms cubic-bezier(0.41, 0.094, 0.54, 0.07)',
  medium: '300ms ease',
  fast: '100ms linear',
} as const

export const hovers = {
  raise: {
    transition: 'all 0.2s cubic-bezier(0, 0, 0.22, 1)',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'bottom',
      transform: 'translateY(-0.5rem)',
      zIndex: 5,
    },
  },
}

/**
 * @file Functions for working with Tizra meta-types and meta-objects.
 *
 * In the Tizra system, a meta-object is an object (such as a PDF) with
 * associated metadata, and the meta-type defines what metadata properties
 * are available on the object. For example a Book has an Authors property.
 */

import * as R from 'rambdax'
import {stripTags} from './html'
import {logger} from './log'
import {
  Excerpt,
  Falsy,
  GenericMetaObject,
  isTocEntry,
  MetaObject,
  notNullish,
  Nullish,
  OEmbed,
  SearchResult,
  SearchResultTypes,
  TocEntry,
  User,
} from './types'
import {URLish} from './urlish'
import {semiSplit, truthyArray} from './utils'

const log = logger('tizra/meta')

type MO = MetaObject | Nullish

type MOT = TocEntry | MO

type Props = string | Falsy | Array<string | Falsy>

/**
 * String representation of metaObj name.
 *
 * Publication titles can contain markup, but this function returns the string
 * representation for select options, etc.
 */
export function name(metaObj: MOT) {
  if (metaObj) {
    let name = isTocEntry(metaObj) ? metaObj.title : metaObj.name
    name &&= stripTags(name).trim()
    if (name) {
      return name
    }
  }
}

/**
 * Find the first named property that returns non-nil. Empty strings are
 * valid values unless test is overridden (e.g. Boolean or truthy). This returns
 * rich types such as arrays and objects.
 */
export function prop(
  metaObj: MOT,
  propNames: Props,
  test: (v: any) => any = notNullish,
  defaultValue: unknown = undefined,
) {
  const names = truthyArray(
    typeof propNames === 'string' ? semiSplit(propNames) : propNames,
  )
  const found =
    metaObj && 'props' in metaObj ?
      names.map(propName => metaObj.props[propName]).filter(test)
    : undefined
  return found?.length ? found[0] : defaultValue
}

/**
 * Find the first named property that returns non-nil, and format it as
 * a string. Handles arrays by stringifying the first item, does not
 * concatenate or join additional elements.
 *
 * Even though this function is called "string", it will return undefined if
 * there's nothing to return.
 */
export function string(
  metaObj: MOT,
  propNames: Props,
  test?: (v: any) => any,
): string | undefined
export function string<T>(
  metaObj: MOT,
  propNames: Props,
  test: (v: any) => any,
  defaultValue: T,
): string | T
export function string(
  metaObj: MOT,
  propNames: Props,
  test: (v: any) => any = notNullish,
  defaultValue?: any,
) {
  const sentinal = {}
  let v = prop(metaObj, propNames, test, sentinal)
  if (Array.isArray(v)) {
    v = v[0]
  }
  return (
    typeof v === 'string' ? v
    : v === sentinal ? defaultValue
    : `${v}`
  )
}

/**
 * Return the parent id of a meta-object
 */
export function parentId(metaObj: MOT) {
  if (metaObj) {
    return (
      metaObj.parent?.tizraId ||
      ('bookTizraId' in metaObj && metaObj.bookTizraId) ||
      undefined
    )
  }
}

/**
 * Return the parent url-id of a meta-object
 */
export function parentUrlId(metaObj: MOT) {
  return metaObj?.parent?.urlId || parentId(metaObj)
}

interface ReaderHrefOptions {
  page?: number | string // 1-based
  readerQuery?: ConstructorParameters<typeof URLSearchParams>[0]
}

/**
 * Return link to reader for obj, or undefined if N/A
 */
export function readerHref(
  metaObj: MOT,
  {page = '1', readerQuery}: ReaderHrefOptions = {},
) {
  if (!metaObj) {
    return
  }

  const {
    metaType,
    tizraId,
    urlId = tizraId,
    pdfSourceFileName,
  } = metaObj as MetaObject
  const {pageNumber} = metaObj as TocEntry
  const {pages} = metaObj as Excerpt

  if (
    metaType === 'PageRange' ||
    metaType === 'PdfPage' ||
    metaType === 'toc-entry'
  ) {
    const linkParent = parentUrlId(metaObj)
    const linkPage = pageNumber || pages?.[0]
    if (linkParent && linkPage) {
      return URLish.str(`/${linkParent}/${linkPage}`, {search: readerQuery})
    }
  } else if (pdfSourceFileName) {
    if (urlId && page) {
      return URLish.str(`/${urlId}/${page}`, {search: readerQuery})
    }
  }
}

export interface HrefOptions extends ReaderHrefOptions {
  reader?: boolean
  source?: string
}

/**
 * Return a link to obj, or undefined if N/A
 */
export function href(
  metaObj: MOT,
  {page, source, reader, ...options}: HrefOptions = {},
): string | undefined {
  if (!metaObj) {
    return
  }

  const {metaType, tizraId, urlId = tizraId} = metaObj as MetaObject

  if (reader === undefined) {
    if (source) {
      reader = false
    } else if (page) {
      reader = true
    } else {
      reader = metaType === 'PdfPage' || metaType === 'toc-entry'
    }
  }

  if (reader) {
    return readerHref(metaObj, {...options, page})
  }

  if (urlId && source) {
    if (!source.startsWith('~')) source = `~~${source}`
    return URLish.str(`/${urlId}/${source}`)
  }

  if (urlId) {
    return URLish.str(`/${urlId}/`)
  }
}

export function pageThumbnailUrl(s: string, width?: number): string {
  const u = new URLish(s)
  u.searchParams.set('thumbnail', '')
  u.searchParams.set('width', `${roundWidth(width)}`)
  return u.toString()
}

export function isUrl(s: string) {
  try {
    new URL(s)
  } catch {
    return false
  }
  return true
}

export function isPageImage(s: string) {
  const u = new URLish(s)
  return /[/]\S+[/]\d+[.](?:jpg|jpeg|png)$/i.test(u.pathname)
}

function maybePageThumbnailUrl(s: string, width?: number) {
  return isPageImage(s) ? pageThumbnailUrl(s, width) : s
}

function addCacheBuster(s: string, bust: string) {
  const u = new URLish(s)
  if (u.searchParams.has('_')) {
    log.warn('addCacheBuster: already has:', s)
    return s
  }
  u.searchParams.set('_', bust)
  return u.toString()
}

function isRecord<V = unknown, K extends string = string>(
  o: unknown,
): o is Record<K, V> {
  return !!o && typeof o === 'object'
}

export function coverImageFromProp(
  metaObj: MetaObject,
  prop?: string,
  width?: number,
) {
  if (!prop) {
    return undefined
  }

  // metaObj.props.CoverImage = 'xxx' | {url: 'xxx'} | {default: 'xxx'}
  const v = metaObj.props[prop]

  // If CoverImage is empty string, then return undefined so we fall back
  // internally to page image. But if CoverImage is object with empty string
  // url/default then return empty string to prevent internal fallback, because
  // sites using CoverImage as a file prop should supply the page image default
  // if they want it.
  let s =
    typeof v === 'string' ?
      v.trim() || undefined // fall back to internal page image
    : (
      !isRecord(v) ||
      (typeof v.url !== 'string' && typeof v.default !== 'string')
    ) ?
      undefined // weird/missing CoverImage object, use internal fallback
    : (typeof v.url === 'string' && v.url.trim()) ||
      (typeof v.default === 'string' && v.default.trim()) ||
      '' // url and default are empty, but don't use internal fallback

  if (s && !isUrl(s)) {
    if (!s.startsWith('/')) {
      s = `/${metaObj.urlId}/${s}`
    }
    const bust =
      isRecord(v) &&
      typeof v['date-uploaded'] === 'string' &&
      v['date-uploaded'].replace(/\D/g, '')
    if (bust) {
      s = addCacheBuster(s, bust)
    }
    s = maybePageThumbnailUrl(s, width)
  }
  return s
}

export function hasOEmbed(metaObj: MOT) {
  if (!metaObj) {
    return undefined
  }
  if (isTocEntry(metaObj)) {
    return false
  }
  const {hasOEmbed} = metaObj.props
  return typeof hasOEmbed === 'boolean' ? hasOEmbed : (
      metaObj.metaType === 'Video'
    )
}

export function coverImage(
  metaObj: MO | (TocEntry & {parent?: GenericMetaObject}),
  {
    coverImageProp,
    excerptPageImages = true,
    fallbackHack = false,
    oEmbed,
    width,
  }: {
    coverImageProp?: string
    excerptPageImages?: boolean
    fallbackHack?: boolean
    oEmbed?: OEmbed
    width?: number
  } = {},
) {
  if (!metaObj) {
    return
  }

  if (!isTocEntry(metaObj)) {
    const image =
      // coverImageProp gets top priority. This is for places like the Headline
      // Block to use an alternate cover image.
      coverImageFromProp(metaObj, coverImageProp, width) ??
      coverImageFromProp(metaObj, 'CoverImageFile', width) ??
      coverImageFromProp(metaObj, 'CoverImage', width)

    if (image) {
      return image
    }
    if (oEmbed) {
      return oEmbed.thumbnail_url_with_play_button || oEmbed.thumbnail_url
    }
    // If CoverImageFromProp() returns '', that means the CoverImage file prop
    // is being used and therefore responsibility for page image fallback rests
    // on the administrator.
    if (image !== undefined && !fallbackHack) {
      return
    }
  }

  if (metaObj.parent && excerptPageImages) {
    const pageNumber =
      (metaObj as TocEntry).pageNumber || (metaObj as Excerpt).pages?.[0] // toc-entry, PageRange
    if (pageNumber) {
      return pageImage(metaObj.parent, pageNumber, width)
    }
  }

  // Check for pdfSourceFileName to account for publications that consist
  // entirely of attachments.
  if (!isTocEntry(metaObj) && metaObj.pdfSourceFileName) {
    return pageImage(metaObj, 1, width)
  }
}

function roundWidth(w?: number) {
  return (w && Math.ceil(w / 300) * 300) || 300
}

export function pageImage(
  book: GenericMetaObject,
  pageNum: number,
  width?: number,
): string {
  return pageThumbnailUrl(`/${book.urlId}/${pageNum}.jpg`, width)
}

const EN_DASH = '\u{2013}'
const THIN_SPACE = '\u{2009}'
export const PAGE_JOINER = `${THIN_SPACE}${EN_DASH}${THIN_SPACE}`

const inBounds = (lower: number, upper: number) => (n: number) =>
  n < lower ? lower
  : n > upper ? upper
  : n

export function formatPages(
  _pages?: (number | string)[],
  logicalPages?: string[],
) {
  if (!logicalPages || !_pages?.length) {
    return
  }
  const pages = _pages.map((n: any) => parseInt(n))
  const prefix = [...new Set(pages)].length === 1 ? 'page ' : 'pages '
  const formatPair = (pair: number[]) =>
    R.piped(
      pair,
      R.sortBy(R.identity), // ensure a comes before b
      R.map(inBounds(1, logicalPages.length)),
      R.map(p => logicalPages[p - 1]),
      R.uniq,
      R.join(PAGE_JOINER),
    )
  const joined = R.piped(
    pages,
    R.splitEvery(2),
    R.map(formatPair),
    R.join(', '),
  )
  return `${prefix}${joined}`
}

export function userProfileName(userData?: User | Falsy) {
  return (
    (userData &&
      (string(userData, 'ProfileName')?.trim() ||
        string(userData, 'FirstName')?.trim() ||
        userData.name?.replace(/@.*/, '').trim())) ||
    'Profile'
  )
}

export function userCheckoutName(userData?: User | Falsy) {
  return !userData ? '' : (
      string(userData, 'CheckoutName')?.trim() ||
        userData.email?.trim() ||
        userData.name?.trim() ||
        '(unknown)'
    )
}

export function userInfoHref(userData?: User | null) {
  return userData ? `/${userData.urlId}/~userInfo` : ''
}

export function singular(displayName: string) {
  return /.#./.test(displayName) ?
      displayName.split('#', 2)[0]
    : displayName.replace(/\(s\)$/, '')
}

export function plural(displayName: string, count = 0) {
  return (
    count === 1 ? singular(displayName)
    : /.#./.test(displayName) ? displayName.split('#', 2)[1]
    : displayName.replace(/\(s\)$/, 's')
  )
}

export function any(displayName: string) {
  return displayName ? `Any ${singular(displayName)}` : ''
}

export function snippet(metaObj?: SearchResultTypes) {
  return metaObj && (metaObj as SearchResult).snippet
}

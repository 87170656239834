import {useEffect, useMemo} from 'react'
import {useForceRender} from './useForceRender'
import {useHydrating} from './useHydration'

/**
 * Dynamically test a media query.
 */
export const useMedia = (q: string, defaultValue: boolean): boolean => {
  // Optional chaining for matchMedia as well as window, because the jsdom
  // window object doesn't supply matchMedia.
  const mq = useMemo(
    () => (typeof window === 'undefined' ? undefined : window.matchMedia?.(q)),
    [q],
  )

  // Listen to the media query, force render when it changes.
  const forceRender = useForceRender()
  useEffect(() => {
    if (mq) {
      if (mq.addEventListener) {
        mq.addEventListener('change', forceRender)
        return () => {
          mq.removeEventListener('change', forceRender)
        }
      } else if (mq.addListener) {
        mq.addListener(forceRender)
        return () => {
          mq.removeListener(forceRender)
        }
      }
    }
  }, [forceRender, mq])

  return useHydrating() ? defaultValue : mq?.matches ?? defaultValue
}

type ApiErrorProps = {
  url: string
  method: string
  headers: Record<string, string>
  params: unknown
  error?: any
  response?: Response
  text?: string
}

export class ApiError extends Error {
  url: string
  method: string
  request: {
    headers: Record<string, string>
    params: unknown
  }
  response?: {
    headers: Record<string, string>
    text?: string
  }
  status?: number

  constructor({
    url,
    method,
    headers,
    params,
    error,
    response,
    text,
  }: ApiErrorProps) {
    // Calling super with message doesn't work
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }

    this.message =
      error ? `${error} ${url} ${error?.message}`
      : response ? `${response.status} ${url}`
      : `ERROR ${url}`

    this.url = url
    this.method = method
    this.request = {headers, params}
    this.response = response && {
      headers: Object.fromEntries(response.headers.entries()),
      text,
    }
    this.status = response?.status

    // https://github.com/rollbar/rollbar.js/pull/687
    this.name = 'ApiError'
  }
}

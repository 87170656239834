import {get, system} from 'styled-system'

// copy pasta from styled-system grid module
const defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
}

// copy pasta from styled-system layout module
const isNumber = n => typeof n === 'number' && !isNaN(n)
const getWidth = (n, scale) =>
  get(scale, n, !isNumber(n) || n > 1 ? n : n * 100 + '%')

export const columns = system({
  columnCount: true,
  columnWidth: {
    property: 'columnWidth',
    scale: 'sizes',
    transform: getWidth,
  },
  columnGap: {
    property: 'columnGap',
    scale: 'space',
    defaultScale: defaults.space,
  },
  columnRuleColor: {
    property: 'columnRuleColor',
    scale: 'colors',
  },
  columnRuleStyle: {
    property: 'columnRuleStyle',
    scale: 'borderStyles',
  },
  columnRuleWidth: {
    property: 'columnRuleWidth',
    scale: 'borderWidths',
  },
  // shorthands don't use scales
  columns: true,
  columnRule: true,
  columnFill: true,
  columnSpan: true,
})

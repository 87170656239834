/**
 * JSON encode with sorted keys, especially helpful for stable test output.
 */
export const stableJson = (
  x: any,
  {indent = 0, sort = (arr: string[]) => arr.sort()} = {},
) => JSON.stringify(x, sort(flatKeys(x)), indent)

function flatKeys(x: any): string[] {
  return [...new Set(_flatKeys(x))]
}

function* _flatKeys(x: any): Generator<string, void, undefined> {
  if (Array.isArray(x)) {
    for (const k of x) {
      yield* _flatKeys(k)
    }
  } else if (x && typeof x === 'object') {
    yield* Object.keys(x)
    for (const v of Object.values(x)) {
      yield* _flatKeys(v)
    }
  }
}

/**
 * JSON decode without throwing.
 */
export const tryJson = <T>(
  json: string | null | undefined,
  errorFn?: (e: SyntaxError) => void,
): T | undefined => {
  // Because JSON fields can contain unexpected values depending on who wrote to
  // them, we want all of the strings "", "null", and "undefined" to fall back
  // to undefined. If we let this fall through to the try/catch, then "" and
  // "undefined" would emit errors before falling back to the default, and
  // "null" would decode and return null.
  try {
    json = json?.trim()
  } catch {
    // Swallow here, will throw when passed to JSON.parse
  }
  if (
    json !== null &&
    json !== undefined &&
    json !== '' &&
    json !== 'null' &&
    json !== 'undefined'
  ) {
    try {
      return JSON.parse(json)
    } catch (e) {
      if (errorFn) {
        errorFn(e as SyntaxError)
      }
    }
  }
}

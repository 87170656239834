/**
 * Check if two objects contain the same set of keys.
 */
export function eqKeys(a: {[k: string]: unknown}, b: {[k: string]: unknown}) {
  const aks = Object.keys(a)
  const bks = Object.keys(b)
  if (aks.length !== bks.length) {
    return false
  }
  aks.sort()
  bks.sort()
  return aks.every((k, i) => bks[i] === k)
}

/**
 * Partition props according to a list. Drops undefined values to approximate
 * the effect of destructuring, since this function stands in for destructuring
 * in a component.
 */
export function partitionProps<O extends object, K extends keyof O>(
  names: Array<K>,
): (props: O) => [Pick<O, K>, Omit<O, K>]
export function partitionProps<O extends object, K extends keyof O>(
  names: Array<K>,
  props: O,
): [Pick<O, K>, Omit<O, K>]
export function partitionProps<O extends object, K extends keyof O>(
  names: Array<K>, // omit string for simpler impl below
  props?: O,
) {
  function part(props: O) {
    const right = {...props}
    const left = {} as O
    for (const n of names) {
      if (right[n] !== undefined) {
        left[n] = right[n]
        delete right[n]
      }
    }
    return [left, right] as [Pick<O, K>, Omit<O, K>]
  }
  return props ? part(props) : part
}

/**
 * Check if two objects contain identical values. This is faster than R.equals
 * because it won't descend into values.
 */
export function shallowIdentical(a: any, b: any) {
  if (a === b) {
    return true
  }
  // Note arrays are 'object' too.
  if (typeof a !== 'object' || typeof b !== 'object') {
    return false
  }
  // Optimization for arrays and sets.
  if (a.length !== b.length || a.size !== b.size) {
    return false
  }
  // Don't over-optimize for arrays here, since arrays can have additional
  // properties hanging off them.
  if (!eqKeys(a, b)) {
    return false
  }
  for (const [k, av] of Object.entries(a)) {
    if (b[k] !== av) {
      return false
    }
  }
  return true
}

/**
 * Like fromPairs except summing values for duplicate keys.
 */
export function sumPairs(arr: Array<[string, number]>): {[k: string]: number} {
  return arr.reduce(
    (obj, [k, v]) => {
      obj[k] = (obj[k] || 0) + v
      return obj
    },
    {} as {[k: string]: number},
  )
}

// Work around https://github.com/selfrefactor/rambda/pull/666
// Also these are probably faster...

export const dropLastWhile =
  <T>(pred: (item: T) => any) =>
  (items: T[]) => {
    let i = items.length
    while (i && pred(items[i - 1])) {
      i--
    }
    return items.slice(0, i)
  }

export const takeLastWhile =
  <T>(pred: (item: T) => any) =>
  (items: T[]) => {
    let i = items.length
    while (i && pred(items[i - 1])) {
      i--
    }
    return items.slice(i)
  }

export const dropWhile =
  <T>(pred: (item: T) => any) =>
  (items: T[]) => {
    let i = 0
    while (i < items.length && pred(items[i])) {
      i++
    }
    return items.slice(i)
  }

export const takeWhile =
  <T>(pred: (item: T) => any) =>
  (items: T[]) => {
    let i = 0
    while (i < items.length && pred(items[i])) {
      i++
    }
    return items.slice(0, i)
  }

export const partitions = <T>(arr: T[], fns: Array<(x: T) => any>) => {
  const results = [...fns.map(() => []), []] as T[][]
  for (const x of arr) {
    const i = fns.findIndex(fn => fn(x))
    results[i === -1 ? fns.length : i].push(x)
  }
  return results
}

export const mapToObj = <K extends string | number | symbol, V>(
  fn: (k: K) => V,
  arr: Readonly<K[]> | K[],
) => Object.fromEntries(arr.map(k => [k, fn(k)])) as {[k in K]: V}

export const sort = <T>(arr: T[]) => arr.slice().sort() as T[]

export const isEmptyObj = <T extends object>(x: T) => {
  for (const k in x) {
    if (Object.hasOwn(x, k)) {
      return false
    }
  }
  return true
}

const nativeIsDisjointFrom =
  'isDisjointFrom' in Set.prototype ?
    (a: Set<any>, b: Set<any>) => a.isDisjointFrom(b)
  : undefined

export const polyIsDisjointFrom = (a: Set<any>, b: Set<any>) => {
  if (b.size < a.size) [a, b] = [b, a]
  for (const aa of a) {
    if (b.has(aa)) return false
  }
  return true
}

export const isDisjointFrom = nativeIsDisjointFrom || polyIsDisjointFrom

const nativeIsSupersetOf =
  'isSupersetOf' in Set.prototype ?
    (a: Set<any>, b: Set<any>) => a.isSupersetOf(b)
  : undefined

export const polyIsSupersetOf = (a: Set<any>, b: Set<any>) => {
  if (a.size < b.size) return false
  for (const bb of b) {
    if (!a.has(bb)) return false
  }
  return true
}

export const isSupersetOf = nativeIsSupersetOf || polyIsSupersetOf

const nativeUnion =
  'union' in Set.prototype ?
    <A, B>(a: Set<A>, b: Set<B>): Set<A | B> => a.union(b)
  : undefined

export const polyUnion = <A, B>(a: Set<A>, b: Set<B>): Set<A | B> =>
  new Set([...a, ...b])

export const union = nativeUnion || polyUnion

const nativeDifference =
  'difference' in Set.prototype ?
    <A>(a: Set<A>, b: Set<any>): Set<A> => a.difference(b)
  : undefined

export const polyDifference = <A>(a: Set<A>, b: Set<any>): Set<A> =>
  new Set([...a].filter(aa => b.has(aa)))

export const difference = nativeDifference || polyDifference

interface FlattenOptions {
  prefix?: string
  joiner?: string
  transformKey?: (
    key: string,
    prefix: string,
    obj: object,
  ) => string | undefined
  transformValue?: (value: unknown) => unknown | undefined
}

export function flatten(
  obj: object,
  {
    prefix = '',
    joiner = '.',
    transformKey = k => k,
    transformValue = v => v,
  }: FlattenOptions = {},
) {
  const result: Record<string, unknown> = {}
  function flat(o: object, p: string) {
    const pairs = Array.isArray(o) ? o.map((v, i) => [i, v]) : Object.entries(o)
    for (const [k, v] of pairs) {
      const tk = transformKey(k, p, o)
      if (tk === undefined) continue
      if (v && typeof v === 'object') {
        flat(v, p + tk + joiner)
      } else {
        const tv = transformValue(v)
        if (tv !== undefined) {
          result[p + tk] = tv
        }
      }
    }
  }
  flat(obj, prefix)
  return result
}

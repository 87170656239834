import {darken, lighten} from 'polished'
import {Branding} from './branding'

export const getColors = (brand: Branding['colors']) => {
  const scales = {
    alphaBlack: {
      90: 'rgba(0, 0, 0, 0.85)',
      60: 'rgba(0, 0, 0, 0.6)',
      40: 'rgba(0, 0, 0, 0.4)',
      20: 'rgba(0, 0, 0, 0.2)',
    },
    alphaWhite: {
      90: 'rgba(255, 255, 255, 0.9)', // e6
      60: 'rgba(255, 255, 255, 0.8)', // cc
      40: 'rgba(255, 255, 255, 0.7)', // b3
      20: 'rgba(255, 255, 255, 0.2)', // 33
    },
    nude: {
      80: '#333333',
      60: '#666666',
      40: '#999999',
      30: '#b3b3b3',
      20: '#cccccc',
      10: '#e6e6e6',
      5: '#f2f2f2',
      0: '#ffffff',
    },
    brandForm: {
      100: lighten(0.27, brand.button),
      200: lighten(0.137, brand.button),
      500: brand.button,
      700: darken(0.05, brand.button),
    },
    brandText: {
      100: lighten(0.27, brand.link),
      200: lighten(0.137, brand.link),
      500: brand.link,
      700: darken(0.05, brand.link),
    },
    success: {
      100: '#C5F3C9',
      200: '#52B45A',
      500: '#2F9237',
    },
    danger: {
      100: '#FFD8D9',
      200: '#E02F32',
      500: '#BB1316',
      700: '#94080A',
    },
    warning: {
      100: '#FFD0B4',
      200: '#F17D39',
      500: '#D35E1A',
      700: '#B34607',
    },
    info: {
      100: '#C5DBFB',
      200: '#3E7BD7',
      500: '#1B57B2',
    },
  }

  return {
    colors: {
      // typography
      textColor: scales.alphaBlack[90],
      textColorMuted: scales.alphaBlack[60],
      textColorLight: scales.alphaBlack[40],
      textError: scales.danger[700],
      textRequired: scales.danger[200],
      textInvertedColor: scales.alphaWhite[90],
      textInvertedColorMuted: scales.alphaWhite[60],
      textUnderline: scales.alphaBlack[60], // textColorMuted
      linkColor: scales.brandText[500],
      selectionBgColor: scales.brandText[100],
      selectionColor: scales.alphaBlack[90],
      // backgrounds
      backgroundColor: 'transparent',
      backgroundColorWhite: scales.nude[0],
      backgroundColorLight: scales.nude[5],
      backgroundColorMedium: scales.nude[10],
      backgroundColorDark: scales.nude[80],
      backgroundColorOverlay: scales.alphaWhite[60],
      backgroundError: scales.danger[100],
      // borders
      borderColor: scales.nude[10],
      borderColorLight: scales.nude[5],
      borderHoverColor: scales.nude[20],
      borderSelectedColor: scales.nude[30],
      borderFocusColor: scales.nude[30],
      borderError: scales.danger[500],
      // buttons and tags - TODO
      buttonSolidColor: scales.brandForm[500],
      buttonSolidHover: scales.brandForm[700],
      buttonSolidFocus: scales.brandForm[700],
      buttonOutlineColor: scales.brandForm[500],
      buttonOutlineHover: scales.brandForm[500],
      buttonOutlineFocus: scales.brandForm[500],
      buttonNeutralColor: scales.alphaBlack[90],
      buttonNeutralHover: scales.alphaBlack[90],
      buttonNeutralFocus: scales.alphaBlack[90],
      buttonDisabled: scales.nude[20],
      buttonDisabledColor: scales.alphaBlack[60],
      tagNeutralColor: scales.nude[10],
      tagNeutralHover: scales.nude[20],
      // cart indicator
      notificationBubble: scales.danger[200],
    },
    scales,
  }
}

import isPropValid from '@emotion/is-prop-valid'
import {transient} from 'quickstart/theme/utils'
import * as R from 'rambdax'
import {ComponentProps, JSX, JSXElementConstructor} from 'react'
import styled, {
  ServerStyleSheet,
  ThemeProvider,
  createGlobalStyle,
  css,
  keyframes,
  useTheme,
} from 'styled-components'
import {
  BackgroundProps,
  BorderProps,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  ShadowProps,
  SpaceProps,
  TypographyProps,
  background,
  border,
  color,
  compose,
  createParser,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  styleFn,
  system as styledSystem,
  typography,
} from 'styled-system'
import {logger} from 'tizra'
import {columns} from './columns'

import {useMedia} from 'quickstart/hooks/useMedia'
import type {BP} from 'quickstart/styled-components/theme/base'

const log = logger('system')
const {browser} = log

type BPX = BP | 'xs' | '_'

const breakpoints =
  (xs: {[k in BPX]?: any}) =>
  ({theme: {breakpoints}}: any) => {
    const ys = []
    for (const [_bp, x] of Object.entries(xs)) {
      const bp = _bp as BPX
      if (bp === 'xs' || bp === '_') {
        ys.push(x)
      } else {
        const b = breakpoints[bp]
        if (b) {
          ys.push(css`
            @media screen and (min-width: ${b}) {
              ${x}
            }
          `)
        } else {
          log.error(`missing breakpoint ${bp}`)
        }
      }
    }
    return ys
  }

export interface SystemProps
  extends BackgroundProps,
    BorderProps,
    ColorProps,
    FlexboxProps,
    GridProps,
    Omit<LayoutProps, 'size'>,
    PositionProps,
    ShadowProps,
    SpaceProps,
    TypographyProps {
  columnCount?: any
  columnWidth?: LayoutProps['width']
  columnGap?: SpaceProps['padding']
  columnRuleColor?: ColorProps['color']
  columnRuleStyle?: BorderProps['borderStyle']
  columnRuleWidth?: BorderProps['borderWidth']
  columns?: any
  columnRule?: any
  columnFill?: any
  columnSpan?: any
  textWrap?: any
  // HACK: styled-components v6 doesn't seem to recognize as prop
  as?: any
}

const textWrap = styledSystem({textWrap: true})

// Different from styled-system's system utility.
const system = compose(
  background,
  border,
  color,
  columns,
  flexbox,
  grid,
  layout,
  // layout module applies "size" to "width" and "height" but we use size
  // generically as a component prop, not as a system prop. This config prevents
  // size="lg" from leaking into styles as {width: lg; height: lg}.
  // @ts-expect-error
  {config: {size: undefined}},
  position,
  shadow,
  space,
  textWrap,
  typography,
) as styleFn

const themeGetter =
  (theme: any) =>
  (path: any, def: any = undefined): any => {
    if (typeof path === 'object' && typeof theme === 'string') {
      ;[path, theme] = [theme, path]
    }
    if (
      browser.assert(
        typeof path === 'string' && typeof theme === 'object',
        `themeGetter called with ${typeof path}, ${typeof theme}`,
      )
    ) {
      const v = R.piped(
        R.path(path, theme),
        R.when(R.identical(undefined), R.always(def)),
      )
      browser.assert(v !== undefined, 'not found in theme:', path)
      return v
    }
  }

const th = (path: any, def?: any) => (props: any) =>
  themeGetter(props?.theme)(path, def)

const useUp = (bp: string, defaultValue = false) => {
  const {breakpoints} = useTheme()!
  const b = breakpoints[bp]
  const matches = useMedia(`(min-width: ${b})`, defaultValue)
  return !b || matches
}

const systemProps = [
  'alignContent',
  'alignItems',
  'alignSelf',
  'background',
  'backgroundColor',
  'backgroundImage',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
  'bg',
  'border',
  'borderBottom',
  'borderBottomColor',
  'borderBottomLeftRadius',
  'borderBottomRightRadius',
  'borderBottomStyle',
  'borderBottomWidth',
  'borderColor',
  'borderLeft',
  'borderLeftColor',
  'borderLeftStyle',
  'borderLeftWidth',
  'borderRadius',
  'borderRight',
  'borderRightColor',
  'borderRightStyle',
  'borderRightWidth',
  'borderStyle',
  'borderTop',
  'borderTopColor',
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderTopStyle',
  'borderTopWidth',
  'borderWidth',
  'borderX',
  'borderY',
  'bottom',
  'boxShadow',
  'color',
  'columnCount',
  'columnFill',
  'columnGap',
  'columnRule',
  'columnRuleColor',
  'columnRuleStyle',
  'columnRuleWidth',
  'columnSpan',
  'columnWidth',
  'columns',
  'display',
  'flex',
  'flexBasis',
  'flexDirection',
  'flexGrow',
  'flexShrink',
  'flexWrap',
  'fontFamily',
  'fontSize',
  'fontStyle',
  'fontWeight',
  'gridArea',
  'gridAutoColumns',
  'gridAutoFlow',
  'gridAutoRows',
  'gridColumn',
  'gridColumnGap',
  'gridGap',
  'gridRow',
  'gridRowGap',
  'gridTemplateAreas',
  'gridTemplateColumns',
  'gridTemplateRows',
  'height',
  'justifyContent',
  'justifyItems',
  'justifySelf',
  'left',
  'letterSpacing',
  'lineHeight',
  'm',
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'maxHeight',
  'maxWidth',
  'mb',
  'minHeight',
  'minWidth',
  'ml',
  'mr',
  'mt',
  'mx',
  'my',
  'opacity',
  'order',
  'overflow',
  'overflowX',
  'overflowY',
  'p',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'pb',
  'pl',
  'position',
  'pr',
  'pt',
  'px',
  'py',
  'right',
  'size',
  'textAlign',
  'textShadow',
  'top',
  'verticalAlign',
  'width',
  'zIndex',
]

const systemPropsSet = new Set(systemProps)
const isSystemProp = (prop: string) =>
  typeof prop === 'string' && systemPropsSet.has(prop)
const notSystemProp = (prop: string) => !isSystemProp(prop)

// Some HTML props we don't want to forward
const nonDefaultValidatorFn = (prop: string) =>
  prop !== 'data' && prop !== 'spacing'

// From styled-components internal utils
const isTag = (s: unknown): s is string =>
  typeof s === 'string' && s !== '' && s.charAt(0) === s.charAt(0).toLowerCase()

const shouldForwardProp = (prop: string, elementToBeCreated: unknown) =>
  prop !== 'theme' &&
  (notSystemProp(prop) ||
    (isTag(elementToBeCreated) &&
      elementToBeCreated.toLowerCase() === 'img' &&
      (prop === 'height' || prop === 'width'))) &&
  (isTag(elementToBeCreated) ?
    isPropValid(prop) && nonDefaultValidatorFn(prop)
  : true)

const omitProps =
  (props: string[], checkShouldForwardProp = true) =>
  (prop: string, elementToBeCreated: unknown) =>
    !props.includes(prop) &&
    (!checkShouldForwardProp || shouldForwardProp(prop, elementToBeCreated))

/**
 * Replace the default styled-components styled.* shortcuts to avoid passing
 * through styled-system props.
 */
Object.keys(styled).forEach(k => {
  // @ts-expect-error
  styled[k] = styled[k].withConfig({shouldForwardProp})
})

export type NonSystemProps<T> = Omit<T, keyof SystemProps>

export type KnownComponent<P = any> =
  | keyof JSX.IntrinsicElements
  | JSXElementConstructor<P>

/**
 * A version of ComponentProps<> that leaves out system props to avoid conflicts
 * when merging interfaces.
 */
export type NonSystemComponentProps<T extends KnownComponent> = NonSystemProps<
  ComponentProps<T>
>

// https://github.com/emotion-js/emotion/issues/1059#issuecomment-444566635
const firstChildSelector = `
& > *:first-child:not(style),
& > style:first-child + *
`

const lastChildSelector = `
& > *:last-child
`

export {
  ServerStyleSheet,
  ThemeProvider,
  background,
  border,
  breakpoints,
  color,
  compose,
  createGlobalStyle,
  createParser,
  css,
  firstChildSelector,
  flexbox,
  grid,
  isSystemProp,
  keyframes,
  lastChildSelector,
  layout,
  notSystemProp,
  omitProps,
  position,
  shadow,
  shouldForwardProp,
  space,
  system,
  systemProps,
  th,
  themeGetter,
  transient,
  typography,
  useTheme,
  useUp,
}

// This must be the default export instead of a named export in order to satisfy
// babel-plugin-styled-components.
export default styled

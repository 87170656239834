import {Hack, Hacks, defaultHacks} from 'quickstart/hacks'
import {useBlockContext} from './useBlockContext'

export function useHack<K extends Hack>(k: K, fallback?: true): Hacks[K]
export function useHack<K extends Hack>(
  k: K,
  fallback: false,
): Hacks[K] | undefined
export function useHack(k: Hack, fallback = true): any {
  return useBlockContext().hacks[k] ?? (fallback ? defaultHacks[k] : undefined)
}

export function allHacks(hacks: Partial<Hacks>): Hacks {
  return {...defaultHacks, ...hacks}
}

export function useHacks(): Hacks {
  return allHacks(useBlockContext().hacks)
}

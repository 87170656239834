/**
 * Helpers for processing specific API responses
 */

import {NestedTocEntry, TocEntry} from './types'

/**
 * Flatten the nested reader/toc response back to a flat TOC. We do this because
 * the normal toc API is paged, but the reader/toc API returns the entire thing.
 */
export const flattenReaderToc = (entries: NestedTocEntry[]): TocEntry[] => {
  const flat = _flattenReaderToc(entries, [])
  // Now that it's flat, remove the children so the JSON stringified react-query
  // cache doesn't explode.
  for (const entry of flat) {
    delete (entry as Partial<NestedTocEntry>).children
    delete (entry as Partial<NestedTocEntry>).hasChildren
  }
  return flat
}
function _flattenReaderToc(entries: NestedTocEntry[], accum: TocEntry[]) {
  for (const entry of entries) {
    accum.push(entry)
    _flattenReaderToc(entry.children, accum)
  }
  return accum
}

/**
 * @file Functions that reach out of the app into the DOM.
 */

import {logger} from './log'

const log = logger('tizra/dom')

export function metaTagValue(name: string) {
  if (typeof document === 'undefined') {
    return null
  }
  const metaTag = document.querySelector(`meta[name="${name}"]`)
  const because = (msg: string) => {
    log.log(`metaTagValue(${name}): ${msg}`)
    return null
  }
  if (!metaTag) {
    return because('meta tag not found')
  }
  const value = metaTag.getAttribute('content')
  if (value === undefined) {
    return because('no content attribute')
  }
  return value
}

export function isVisible(el: HTMLElement) {
  // offsetParent is null if it's hidden.
  // https://stackoverflow.com/a/21696585/347386
  return el.offsetParent !== null
}

export interface HiddenObject {
  node: HTMLElement
  display: HTMLElement['style']['display']
}

export function hide(el: HTMLElement): HiddenObject {
  const hiddenObject = {
    node: el,
    display: el.style.display,
  }
  el.style.display = 'none'
  return hiddenObject
}

export function show({node: el, display}: HiddenObject) {
  el.style.display = display
}

interface CleanupProps<E extends Node> {
  node: E
  callback: (elem: E) => void
  log: ReturnType<typeof logger>
}

export const onDisappear = <T extends HTMLElement>({
  node,
  callback,
  log,
}: CleanupProps<T>) => {
  const io = new IntersectionObserver(
    entries => {
      if (!entries[entries.length - 1].isIntersecting) {
        log.log('detected disappearance')
        callback(node)
      }
    },
    {root: document.body, threshold: 0},
  )
  return () => io.disconnect()
}

export const onHide = <T extends HTMLElement>({
  node,
  callback,
  log,
}: CleanupProps<T>) => {
  const mo = new MutationObserver(mutations => {
    for (const m of mutations) {
      if (m.type === 'attributes' && node.style.display === 'none') {
        log.log('detected hide')
        callback(node)
      }
    }
  })
  mo.observe(node, {attributes: true, childList: false, subtree: false})
  return () => mo.disconnect()
}

export const getAncestry = (
  node: Node | null | undefined,
  stopAt?: Node | null | undefined,
) => {
  const nodes: Node[] = []
  for (
    let p = node?.parentNode;
    p && p !== stopAt;
    p = p === document ? null : p.parentNode
  ) {
    nodes.push(p)
  }
  return nodes
}

export const onRemove = <T extends Node>({
  node,
  callback,
  log,
}: CleanupProps<T>) => {
  const nodes = getAncestry(node)
  const mo = new MutationObserver(mutations => {
    for (const m of mutations) {
      for (const r of m.removedNodes) {
        if (nodes.includes(r)) {
          log.log('detected remove', r)
          callback(node)
          return
        }
      }
    }
  })
  for (const n of nodes) {
    mo.observe(n, {attributes: false, childList: true, subtree: false})
  }
  return () => mo.disconnect()
}

export const formValue = (form: HTMLFormElement) => (name: string) => {
  const input = form.elements.namedItem(name)
  return (
    input &&
    (
      input as
        | HTMLInputElement
        | HTMLTextAreaElement
        | HTMLSelectElement
        | RadioNodeList
    ).value
  )
}

export const navigationType = () =>
  typeof window === 'undefined' || !window.performance ?
    undefined
  : (
      window.performance.getEntriesByType?.(
        'navigation',
      ) as PerformanceNavigationTiming[]
    )[0]?.type

export const uptimeSeconds = () => {
  const boot =
    typeof window !== 'undefined' && window.performance?.timing?.loadEventEnd
  if (!boot) return 0
  return Math.floor((Date.now() - boot) / 1000)
}

import {isbot} from 'isbot'
import type {WindowTizraApi} from 'quickstart/hooks/useApi'
import type {ClientEntryQuickstart} from '../entry-client'
import {IS_NODE} from './is-node'
import {parseBool} from './utils'

declare global {
  interface Window {
    tizra: {
      _apiQueue?: Array<{
        name: string
        args: any[]
        resolve: (value: unknown) => void
        reject: (reason: any) => void
      }>
      api: WindowTizraApi
      customLoginUrl?: string
      customLogoutUrl?: string
      login: (view?: 'signin' | 'register' | 'resetPassword') => void
      logout: () => void
      registerUser: () => void
      resetPassword: () => void
      quickstart: ClientEntryQuickstart & {
        fields?: unknown // owned by quickstartMacros.ftl
        _ranScripts?: Set<string>
      }
      setDebug: (enabled?: boolean | 'session') => boolean | 'session'
      trackPageview: (
        page?:
          | string
          | {
              page?: string
              title?: string
              referrer?: string
            },
      ) => void
      trackEvent: (
        category: string,
        action: string,
        opt_label?: string,
        opt_value?: any,
        opt_noninteraction?: any,
      ) => void
    }
    IS_STORYBOOK?: true
  }
}

if (typeof window !== 'undefined') {
  const stubs: Window['tizra'] = {
    api: new Proxy({} as WindowTizraApi, {
      get(_, name) {
        return (...args: any[]) => {
          console.error('stub api', name, args)
          return Promise.reject('called stub')
        }
      },
    }),
    login: () => console.error('stub login'),
    logout: () => console.error('stub logout'),
    registerUser: () => console.error('stub registerUser'),
    resetPassword: () => console.error('stub resetPassword'),
    quickstart: {
      getBlock: () => console.error('stub getBlock') as any,
      getPalettes: () => (console.error('stub getPalettes'), []),
      renderBlocks: async () => console.error('stub renderBlocks'),
      renderBlockAdmin: () => console.error('stub renderBlockAdmin'),
      renderDesignTab: () => console.error('stub renderDesignTab'),
      replaceField: () => void console.error('stub replaceField'),
    },
    setDebug: () => (console.error('stub setDebug'), false),
    trackPageview: () => console.error('stub trackPageview'),
    trackEvent: () => console.error('stub trackEvent'),
  }
  window.tizra ||= {} as Window['tizra']
  Object.assign(window.tizra, {...stubs, ...window.tizra})
  Object.assign(window.tizra.quickstart, {
    ...stubs.quickstart,
    ...window.tizra.quickstart,
  })
}

// During Linaria babel plugin processing, the source code is evaluated without
// any of the normal environment indicators (node/graal/browser). Stole this
// indicator from the linaria sources, will need to update if they change.
// @ts-expect-error
const IS_LINARIA_BABEL_PLUGIN = typeof __linaria_dynamic_import !== 'undefined'

const IS_GRAAL = typeof globalThis !== 'undefined' && 'Graal' in globalThis

const IS_BROWSER =
  !IS_GRAAL &&
  !IS_NODE &&
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'

const IS_DEV = !!parseBool(import.meta.env.DEV) // true during test too

const IS_PROD = !!parseBool(import.meta.env.PROD)

const IS_SSR = !!parseBool(import.meta.env.SSR)

const IS_TEST = import.meta.env.MODE === 'test'

// This is set by our package.json for chromatic.
const IS_CHROMATIC = !!parseBool(import.meta.env.STORYBOOK_BUILD)

// This is only true in the preview pane.
// https://storybook.js.org/docs/react/faq#how-can-my-code-detect-if-it-is-running-in-storybook
const IS_STORYBOOK_PREVIEW = !!parseBool(
  typeof window !== 'undefined' && window.IS_STORYBOOK,
)

const IS_STORYBOOK = IS_CHROMATIC || IS_STORYBOOK_PREVIEW

const IS_APPLE =
  IS_BROWSER && /Mac|iPod|iPhone|iPad/.test(window.navigator.platform)

const IS_BOT =
  typeof window !== 'undefined' && isbot(window.navigator?.userAgent)

// There are precious few clues in the HTML leading up to the loading of this
// script, but the hostname should be a stable heuristic.
const IS_ADMIN = IS_BROWSER && /^s\d+-admin\./.test(window.location.hostname)

// This comes from release.bash when building a dev release.
const IS_AUTOTEST = !!parseBool(import.meta.env.VITE_AUTOTEST)

const xor = (...args: boolean[]) =>
  args.reduce((sum, a) => sum + Number(a), 0) === 1

// During Linaria processing, none of the environments are set.
// Otherwise, either BROWSER or GRAAL/NODE should be set, but not both.
console.assert(
  xor(IS_LINARIA_BABEL_PLUGIN, IS_BROWSER, IS_GRAAL, IS_NODE),
  `mismatch: LINARIA_BABEL_PLUGIN=${IS_LINARIA_BABEL_PLUGIN} BROWSER=${IS_BROWSER} GRAAL=${IS_GRAAL} NODE=${IS_NODE}`,
)

if (!IS_LINARIA_BABEL_PLUGIN) {
  console.assert(
    !IS_PROD || IS_BROWSER || IS_GRAAL,
    `mismatch: PROD=${IS_PROD} BROWSER=${IS_BROWSER} GRAAL=${IS_GRAAL}`,
  )

  console.assert(
    !IS_SSR || IS_GRAAL || IS_NODE,
    `mismatch: SSR=${IS_SSR} GRAAL=${IS_GRAAL} NODE=${IS_NODE}`,
  )
}

console.assert(
  !IS_BROWSER || IS_DEV || IS_PROD,
  `mismatch: BROWSER=${IS_BROWSER} DEV=${IS_DEV} PROD=${IS_PROD}`,
)

console.assert(
  !IS_BROWSER || !IS_TEST,
  `mismatch: BROWSER=${IS_BROWSER} TEST=${IS_TEST}`,
)

console.assert(IS_NODE === IS_TEST, `mismatch: NODE=${IS_NODE} TEST=${IS_TEST}`)

const MSW_MODE =
  import.meta.env.STORYBOOK_API === 'live' ? 'live'
  : IS_BROWSER ? 'worker'
  : 'server'

const getHostnameSuffix = (): string => {
  // Don't use location from react router because it doesn't include hostname.
  const hostname = typeof window === 'undefined' ? '' : window.location.hostname
  const match = hostname.match(
    /^s\d+-(\w+)\.(?:dev\.agilepublisher|tizrapublisher)\.com$/,
  )
  return match?.[1] || ''
}

const SITE_SUFFIX = getHostnameSuffix()

const TREE = '🌲'

export {
  IS_ADMIN,
  IS_APPLE,
  IS_AUTOTEST,
  IS_BOT,
  IS_BROWSER,
  IS_CHROMATIC,
  IS_DEV,
  IS_GRAAL,
  IS_NODE,
  IS_PROD,
  IS_SSR,
  IS_STORYBOOK,
  IS_STORYBOOK_PREVIEW,
  IS_TEST,
  MSW_MODE,
  SITE_SUFFIX,
  TREE,
}

import {Dict, Nullish} from 'quickstart/types'
import {markdown} from 'quickstart/utils/markdown'
import * as R from 'rambdax'
import {
  IS_STORYBOOK,
  IS_TEST,
  MetaObject,
  PropDef,
  SearchTypes,
  TocEntry,
  butter,
  isTocEntry,
  logger,
  meta,
  notNullishArray,
  nullish,
  partitionProps,
  simplifyHtml,
  simplifyTocHtml,
  stripTags,
} from 'tizra'
import {JsonValue} from 'type-fest'

const log = logger('utils')

type FormatType = 'date' | 'html' | 'markdown' | 'text'

export interface FormatOptions {
  date?: Intl.DateTimeFormatOptions | boolean
  detectHtml?: boolean
  html?: 'simplify' | 'title' | boolean
  joiner?: string
  br?: boolean
}

function list<T extends JsonValue, V extends (T | null | undefined)[]>(
  value: V,
  type?: FormatType | string,
  options?: FormatOptions,
): T[]
function list<V extends JsonValue>(
  value: V | null | undefined,
  type?: FormatType | string,
  options?: FormatOptions,
): V[]
function list(value: any, type?: FormatType | string, options?: FormatOptions) {
  return notNullishArray(value).filter(
    value => format({value, type, options}).displayValue,
  )
}

type DisplayType = 'html' | 'text' | 'string'

const htmlPatt = /<[/]\w+>|&\w+;/
const looksLikeHtml = (s: string) => htmlPatt.test(s)

export function format({
  value,
  type,
  options: _options,
}: {
  value: JsonValue
  type?: FormatType | string
  options?: FormatOptions
}): {displayType: DisplayType; displayValue: string} {
  const options: FormatOptions = {
    date: true,
    detectHtml: false,
    joiner: ', ',
    html: 'simplify',
    ..._options,
  }

  if (Array.isArray(value)) {
    const formatted = list(value, type, options).map(value =>
      format({value, type, options}),
    )
    return {
      displayValue: formatted
        .map(({displayValue}) => displayValue)
        .join(options.joiner),
      displayType: formatted[0]?.displayType || 'string',
    }
  }

  if (typeof value === 'string') {
    if (type === 'markdown') {
      value = markdown(value, options)
      type = 'html' // FALLS THROUGH
    }

    if (options.detectHtml && (!type || type === 'string' || type === 'text')) {
      if (looksLikeHtml(value)) {
        type = 'html' // FALLS THROUGH
      }
    }

    if (type === 'html') {
      switch (options.html) {
        case 'simplify':
          value = simplifyHtml(value)
          break
        case 'title':
          value = simplifyTocHtml(value)
          break
        default:
          if (!options.html) {
            value = stripTags(value)
            type = 'text'
          }
      }
    }

    if (type === 'date' && options.date) {
      try {
        const d = new Date(value)
        if (!isNaN(d.getTime())) {
          value = d.toLocaleDateString(undefined, {
            timeZone: 'UTC',
            ...butter(options.date),
          })
        }
      } catch (_e) {
        // no problemo
      }
      type = 'string'
    }

    // If we don't have typedefs yet, and value is a string, we don't know if it
    // should be html. Strip tags and display as text (preserves newlines in
    // markdown), then rendering will improve when typedefs arrive.
    if (!type) {
      value = stripTags(value).replace(/\n{3,}/g, '\n\n')
      type = 'text'
    }
  }

  const displayValue =
    typeof value === 'string' ? value.trim()
    : nullish(value) ? ''
    : `${value}`
  const displayType =
    type === 'html' ? 'html'
    : type === 'text' ? 'text'
    : 'string'
  return {displayValue, displayType}
}

interface GetPropDisplayReturn {
  count: number
  displayName: string
  displayType: DisplayType
  displayValue: string
  name: string
  type?: string
  value: any
}

const getDefaultFormatOptions = (
  name: string,
  metaType: string,
  typeDefs?: SearchTypes,
): FormatOptions | undefined => {
  // Detect HTML in titles.
  if (name === '_name' || name === typeDefs?.[metaType]?.namePropName) {
    return {detectHtml: true, html: 'title'}
  }
}

const getPropDisplay = ({
  name,
  value,
  metaType,
  typeDefs,
  options: _options,
}: {
  name: string
  value: JsonValue
  metaType: string
  propDef?: PropDef
  typeDefs?: SearchTypes
  options?: FormatOptions
}): GetPropDisplayReturn => {
  const propDef = typeDefs?.[metaType]?.propDefsIncludingSubtypes[name]
  const type = propDef?.type
  const options = {
    ...getDefaultFormatOptions(name, metaType, typeDefs),
    ..._options,
  }
  const count = list(value, type, options).length
  const displayName = meta.plural(propDef?.displayName || '', count)
  const {displayValue, displayType} = format({value, type, options})
  return {
    count,
    displayName,
    displayType,
    displayValue,
    name,
    type,
    value,
  }
}

const EMPTY_OBJ = Object.freeze({})

export const getPropDisplays = (function () {
  // Close over key and value storage for memoization. We only memoize a single
  // value at a time, on the basis that this function is often called multiple
  // times in sequence for one metaObj/typeDefs combination.
  let pk: any = {},
    v: any
  return (
    // This is more liberal than the underlying getPropDisplay, which requires
    // a not-undefined MetaObject, and doesn't accept TocEntry.
    metaObj?: MetaObject | TocEntry,
    typeDefs?: SearchTypes,
    options?: FormatOptions,
  ): Dict<GetPropDisplayReturn> => {
    if (!metaObj || isTocEntry(metaObj)) {
      return EMPTY_OBJ
    }
    if (!('props' in metaObj)) {
      // @ts-expect-error metaObj.metaType is never because we don't expect this
      log.warn(`missing props in ${metaObj.metaType}`)
      return EMPTY_OBJ
    }
    // Check for memoization match.
    const nk = {metaObj, typeDefs, options}
    if (
      nk.metaObj !== pk.metaObj ||
      nk.typeDefs !== pk.typeDefs ||
      !R.equals(nk.options, pk.options)
    ) {
      // In addition to memoization, make a lazy proxy so that, for example,
      // we don't need to process long-form markdown until the caller actually
      // tries to access it.
      const lazy: Dict<undefined | GetPropDisplayReturn> = R.map(
        R.always(undefined),
        metaObj.props,
      )
      v = new Proxy(lazy, {
        get(lazy, prop) {
          if (
            typeof prop === 'string' &&
            prop in lazy &&
            lazy[prop] === undefined
          ) {
            lazy[prop] = getPropDisplay({
              name: prop,
              value: metaObj.props[prop],
              metaType: metaObj.metaType,
              typeDefs,
              options,
            })
          }
          return (lazy as any)[prop]
        },
      })
      pk = nk
    }
    return v
  }
})()

export type DisplayProps = FormatOptions

const DISPLAY_PROPS = ['date', 'detectHtml', 'html', 'joiner'] as Array<
  keyof DisplayProps
>

export function partitionDisplayProps<P extends DisplayProps>(props: P) {
  return partitionProps(DISPLAY_PROPS, props)
}

export interface MetaProps<T extends MetaObject | TocEntry = MetaObject> {
  metaObj?: T
  metaType?: string
  tizraId?: string
}

const META_PROPS = ['metaObj', 'metaType', 'tizraId'] as Array<keyof MetaProps>

export function partitionMetaProps<
  T extends MetaObject | TocEntry,
  P extends MetaProps<T>,
>(props: P) {
  return partitionProps(META_PROPS, props)
}

export function partitionMetaDisplayProps<
  T extends MetaObject | TocEntry,
  P extends MetaProps<T> & DisplayProps,
>(props: P) {
  const [metaProps, props1] = partitionMetaProps(props)
  const [displayProps, props2] = partitionDisplayProps(props1)
  return [metaProps, displayProps, props2] as const
}

export const capitalize = (s: string) => s.replace(/^./, c => c.toUpperCase())

// https://stackoverflow.com/a/69042224/347386
export function hasOwnProperty<T, K extends PropertyKey>(
  obj: T,
  prop: K,
): obj is T & Record<K, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export const tid = (id: string | Nullish) =>
  (IS_STORYBOOK || IS_TEST) && id ? {'data-testid': id} : null

// https://stackoverflow.com/a/52913382/347386
export class UnreachableCaseError extends Error {
  constructor(val: never) {
    super(`Unreachable case: ${JSON.stringify(val)}`)
  }
}

export const flexAlign = (alignment: 'left' | 'right' | 'center') =>
  alignment === 'left' ? 'flex-start'
  : alignment === 'right' ? 'flex-end'
  : 'center'

export * from './center-content'
export * from './color'
export * from './create-event'
export * from './field-styles'
export * from './isomorphic-observer'
export * from './markdown'
export * from './overflow-ellipsis'
export * from './preventDefault'
export * from './variants'
export * from './walk'
export * from './wrap-children'

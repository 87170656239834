import * as R from 'rambdax'
import {HasRequiredKeys, JsonValue} from 'type-fest'
import {ApiError} from './exceptions'
import {Info, Infos, infos} from './info'
import {IS_NODE} from './is-node'
import {stableJson} from './json'
import {logger} from './log'
import {toQueryString} from './utils'

const log = logger('tizra/client')

type ParamsType<T extends Info> = Parameters<T['paramsFn']>[0]

type ResponseType<T extends Info> = Awaited<ReturnType<T['dataFn']>>

type PathInfo = Omit<Info, 'path'> & {path: Exclude<Info['path'], null>}

type PerformInit = Omit<RequestInit, 'headers'> & {
  headers?: Record<string, string>
}

type TizraCall<K extends keyof Infos> =
  Infos[K] extends {path: null} ?
    (
      path: string,
      params?: ParamsType<Infos[K]>,
      options?: PerformInit,
    ) => Promise<ResponseType<Infos[K]>>
  : HasRequiredKeys<ParamsType<Infos[K]>> extends true ?
    (
      params: ParamsType<Infos[K]>,
      options?: PerformInit,
    ) => Promise<ResponseType<Infos[K]>>
  : (
      params?: ParamsType<Infos[K]>,
      options?: PerformInit,
    ) => Promise<ResponseType<Infos[K]>>

export type TizraClient = {
  [K in keyof Infos]: TizraCall<K>
}

export function tizraClient({
  fetch,
  prefix = '/api/',
}: {
  fetch: typeof window.fetch
  prefix?: string
}): TizraClient {
  return R.map(<I extends Info>(info: I) => {
    const perform = async (
      _path: PathInfo['path'],
      _params: ParamsType<I>,
      {method = info.method, ..._init}: PerformInit = {},
    ) => {
      // Resolve path which might be a function.
      const path = R.piped(
        typeof _path === 'function' ? _path(_params) : _path,
        path => (path.startsWith('/') ? path : `${prefix}${path}`),
      )

      // Resolve params by paramsFn (typically converting to kebab-case)
      const params = info.paramsFn(_params)

      // Build the URL and RequestInit depending on the method.
      // The big difference here is that POST/PUT get params converted to body,
      // but GET/DELETE get params appended as query string.
      const [url, init, errorParams] =
        method === 'POST' || method === 'PUT' ?
          [
            path,
            {
              ..._init,
              method,
              body: stableJson(params),
              headers: {
                'content-type': 'application/json',
                ..._init.headers,
              },
            },
            params,
          ]
        : [
            `${path}${toQueryString(params, '?')}`,
            {headers: {}, ..._init, method},
            undefined, // omit from ApiError since encoded in url
          ]

      // Shortcut for constructing ApiError with the stuff we have already.
      const apiError = (
        props: Omit<
          ConstructorParameters<typeof ApiError>[0],
          'url' | 'method' | 'headers' | 'params'
        >,
      ) =>
        new ApiError({
          url,
          method,
          headers: init.headers,
          params: errorParams,
          ...props,
        })

      // Attempt to fetch. Convert to ApiError if there's a problem.
      const response = await fetch(url, init).catch(error => {
        throw apiError({error})
      })

      // Attempt to decode JSON response. This does not throw ApiError because
      // we defer that to isOk to decide. We do response.text() then
      // JSON.parse() so that we have the text in case of failure. This may be
      // slightly less efficient than response.json()
      const contentType =
        response.headers.get('content-type')?.split(';')[0].toLowerCase() || ''
      const text =
        contentType === 'application/json' || contentType.startsWith('text/') ?
          await response.text()
        : undefined
      let raw: JsonValue | undefined
      if (contentType === 'application/json') {
        if (!text) {
          log.warn(`received empty JSON response for ${url}`)
          // defer to dataFn to interpret
        } else {
          try {
            raw = JSON.parse(text)
          } catch {
            log.warn(`failed to decode JSON response for ${url}`)
            // defer to dataFn to interpret
          }
        }
      }

      // Throw ApiError if the response is not okay.
      const {status} = response
      const ok = await info.isOk({
        contentType,
        params,
        data: raw,
        response,
        status,
        text,
      })
      if (!ok) {
        throw apiError({response, text})
      }

      // Response appears okay, convert the data (to camelCase).
      // info.dataFn can be async or sync, but await works for both.
      let data
      try {
        data = await info.dataFn({
          contentType,
          params,
          data: raw,
          response,
          status,
          text,
        })
      } catch (error) {
        throw apiError({error, response, text})
      }

      // It's amazing anything works.
      return data
    }
    return Object.assign(
      info.path === null ?
        perform
      : function performPreset(
          params: ParamsType<I>,
          options: PerformInit = {},
        ) {
          return perform(info.path!, params, options)
        },
    )
  }, infos) as TizraClient
}

const localFetch: typeof window.fetch = (...args) => {
  let [url] = args
  if (typeof url === 'string' && url.startsWith('/')) {
    args[0] = 'http://mockchicken.' + url
  }
  return window.fetch(...args)
}

export const fetch =
  typeof window === 'undefined' ? (undefined as any)
  : IS_NODE ? localFetch
  : window.fetch

export const api = tizraClient({fetch})

export const admin = tizraClient({fetch, prefix: '/admin-api/_/'})

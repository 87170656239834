import * as R from 'rambdax'
import {CSSProperties} from 'react'
import {IS_PROD, deepMerge, ensureArray} from 'tizra'
import {LiteralToPrimitive, MergeDeep} from 'type-fest'
import {BPX, bpx, breakpoints} from './breakpoints'
import * as constants from './constants'
import {Theme, defaultTheme} from './theme'

export {css, cx} from '@linaria/core'
export {styled} from '@linaria/react'

export const atBreakpointsWith =
  <V>(fn: (v: V) => CSSProperties | null | undefined) =>
  (input: {[k in BPX]?: V}) =>
    bpx.reduce(
      (output, bp) => {
        const o = bp in input && fn(input[bp] as V)
        if (o) {
          if (bp === '_' || bp === 'xs') {
            Object.assign(output, o)
          } else {
            output[`@media (min-width: ${breakpoints[bp]})`] = o
          }
        }
        return output
      },
      {} as Record<string, any>,
    )

export const atBreakpoints = atBreakpointsWith(
  (x: CSSProperties | null | undefined) => x,
)

/**
 * Convert theme to lookalike object of theme functions:
 *
 * {colors: {green: '#00ff44'}}
 *
 * becomes
 *
 * {colors: {green: ({theme}) => theme.colors.green}}
 *
 */
const toThemeGettersDeep = <T extends Record<string, unknown>>(
  obj: T,
  path: string[] = [],
): ThemeGettersDeep<T> =>
  R.map(
    (v, k) =>
      typeof v === 'object' && v && !Array.isArray(v) ?
        toThemeGettersDeep(v as Record<string, unknown>, [...path, k])
      : themePath([...path, k], v),
    obj,
  ) as ThemeGettersDeep<T>

type ThemeGettersDeep<T> =
  T extends object ? {[K in keyof T]: ThemeGettersDeep<T[K]>}
  : (props: any) => LiteralToPrimitive<T>

const themePath =
  IS_PROD ?
    (path: string[], fallback: unknown) =>
      ({theme}: {theme: Theme}) =>
        R.path(path, theme) ?? fallback ?? ''
  : (path: string[], fallback: unknown) =>
      ({theme}: {theme: Theme}) => {
        const value = R.path(path, theme)
        if (value === undefined) {
          throw new Error(
            `themePath: path=${path} theme=${theme} fallback=${fallback}`,
          )
        }
        return value
      }

/**
 * Combine constants and dynamic theme into single type-safe object for
 * interpolation into Linaria styles.
 */
export const theme = deepMerge({} as LinariaTheme)(
  constants, // don't use as target, because "object is not extensible"
  toThemeGettersDeep(defaultTheme),
)

type LinariaTheme = MergeDeep<typeof constants, ThemeGettersDeep<Theme>>

/**
 * Look for a prop that resolves to a non-empty string or function.
 * If a function, feed the props to it for late resolution from theme.
 *
 * column-gap: ${retheme(['$columnGap', '$gap'], theme.space.xl)};
 *
 * <Flex $gap={theme.space.xxl} />
 */
export const retheme =
  <P extends object, K extends keyof P>(
    ks: K | K[],
    fallback: string | ((props: P) => string) = '',
  ) =>
  (props: P) => {
    for (const k of ensureArray(ks)) {
      const v = props[k]
      if (v !== undefined && v !== '') {
        return typeof v === 'function' ? v(props) : v
      }
    }
    return typeof fallback === 'function' ? fallback(props) : fallback
  }

export type Rethemable = string | ((props: {theme: Theme}) => string)

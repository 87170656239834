import {useState, useRef} from 'react'

/**
 * React hook to force a re-render, for hooks with fancy state management.
 */
export const useForceRender = () => {
  const [, emit] = useState<any>()

  // Stable function response, like useCallback without checking.
  return useRef(() => {
    emit({}) // unique obj to force
  }).current
}

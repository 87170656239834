interface URLishOptions {
  base?: string | URL
  search?: ConstructorParameters<typeof URLSearchParams>[0]
  hash?: string
}

const BASE = 'https://urlish.'

export class URLish extends URL {
  removeSlash = false

  // Allow URLish to stand in for Location type
  // https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
  key = 'default'
  state = null

  constructor(url: string | URL, options?: string | URL | URLishOptions) {
    const {
      base: _base,
      search,
      hash,
    }: URLishOptions =
      typeof options === 'string' ? {base: options} : options || {}
    let base = _base && `${_base}`
    try {
      new URL(url, base)
    } catch {
      if (!base) {
        base = BASE
      } else {
        if (!base.startsWith('/')) base = '/' + base
        base = BASE + base
      }
    }
    super(url, base)
    this.removeSlash = !`${url}`.startsWith('/') && !`${_base}`.startsWith('/')
    if (search !== undefined) {
      this.search = new URLSearchParams(search).toString()
    }
    if (hash !== undefined) {
      this.hash = hash
    }
  }

  get pathname() {
    return super.pathname
  }

  set pathname(s: string) {
    super.pathname = s
    this.removeSlash &&= !s.startsWith('/')
  }

  static str(...args: ConstructorParameters<typeof URLish>): string {
    return new URLish(...args).toString()
  }

  toString() {
    let s = super.toString()
    if (this.origin === BASE) {
      s = s.substring(this.origin.length)
      if (this.removeSlash && s[0] === '/') {
        s = s.substring(1)
      }
    }
    return s
  }
}

import * as H from 'quickstart/hacks'
import {
  isSessionError,
  type BlockContext,
  type BlockContextFromServer,
} from 'quickstart/types'
import * as R from 'rambdax'
import * as React from 'react'
import {defaults, ensureArray, logger} from 'tizra'

const log = logger('useBlockContext')

export const defaultBlockContextFromServer: BlockContextFromServer = {
  debugFlags: '',
  globalConfig: {},
  hacks: [],
  isMobileUserAgent: false,
  location: '/',
  metaObject: false,
  metaType: '',
  pageId: '',
  pageObject: false,
  pubType: 'LIVE',
  quickSearchFields: defaults.quickSearchFields,
  renderedAt: '',
  sessionErrors: [],
  successUrl: '',
  tizraId: '',
}

/**
 * Convert from server-provided block context to internal block context.
 *
 * We expect to receive a fully-populated BlockContextFromServer, but this
 * function is typed to receive a partial to avoid tripping on something
 * missing from the server unexpectedly.
 */
const fromServerUntracked = (
  context: Partial<BlockContextFromServer>,
  checkMissing = true,
): BlockContext => {
  if (checkMissing) {
    const miss = Object.keys(defaultBlockContextFromServer).filter(
      k => !(k in context),
    )
    const extra = Object.keys(context).filter(
      k => !(k in defaultBlockContextFromServer),
    )
    if (miss.length) {
      if (
        miss.length === Object.keys(defaultBlockContextFromServer).length &&
        extra.length === 0
      ) {
        log.error('BlockContextProvider received empty object')
      } else {
        log.error(
          'BlockContextProvider received missing keys: ' +
            miss.sort().join(' '),
        )
      }
    }
    if (extra.length) {
      log.debug?.(
        'BlockContextProvider received extra keys: ' + extra.sort().join(' '),
      )
    }
  }
  return {
    ...defaultBlockContextFromServer,
    ...context,
    hacks: H.fromServer(context.hacks, false),
    sessionErrors: ensureArray(context.sessionErrors).filter(isSessionError),
  }
}

export const fromServer = (
  serverContext: Partial<BlockContextFromServer>,
  {
    checkMissing = true,
    usedProps,
  }: {checkMissing?: boolean; usedProps?: Set<string>} = {},
): BlockContext => {
  const untracked = fromServerUntracked(serverContext, checkMissing)
  const context =
    usedProps ?
      new Proxy(untracked, {
        get(target, prop, receiver) {
          if (typeof prop === 'string') usedProps.add(prop)
          return Reflect.get(target, prop, receiver)
        },
      })
    : untracked
  return context
}

export const toServer = (context: BlockContext): BlockContextFromServer => ({
  ...context,
  hacks: H.toServer(context.hacks),
})

export const defaultBlockContext: BlockContext = fromServerUntracked(
  defaultBlockContextFromServer,
)

const BC = React.createContext<BlockContext>(defaultBlockContext)

export const BlockContextProvider = ({
  context,
  children,
}: {
  context: BlockContext
  children: React.ReactNode
}) => {
  return <BC.Provider value={context}>{children}</BC.Provider>
}

export function useBlockContext<K extends keyof BlockContext>(
  k: K,
): BlockContext[K]
export function useBlockContext<K extends keyof BlockContext>(
  k: K[],
): Pick<BlockContext, K>
export function useBlockContext(): BlockContext
export function useBlockContext<K extends keyof BlockContext>(k?: K | K[]) {
  const context = React.useContext(BC)
  return (
    Array.isArray(k) ? R.pick(k, context)
    : k ? context[k]
    : context
  )
}

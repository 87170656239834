import {PartialDeep} from 'type-fest'
import {Falsish, isObjectType, isPlainObject} from './types'

type ArrayPolicy = 'concat' | 'merge' | 'replace'

type UnknownPolicy = 'throw' | 'passthru'

interface Options {
  arrays: ArrayPolicy
  unknown: UnknownPolicy | ((v: object) => any)
  pred: (v: unknown) => boolean
}

const defaults: Options = {
  arrays: 'replace',
  unknown: 'passthru',
  pred: v => v !== undefined,
}

export const mergeFns = (_options?: Partial<Options>) => {
  const options = {...defaults, ..._options}
  const {arrays, unknown, pred} = options

  const unk =
    unknown === 'passthru' ? (v: object) => v
    : unknown === 'throw' ?
      (v: object) => {
        throw new TypeError(`Can't clone unknown type: ${className(v)}`)
      }
    : unknown

  /**
   * Deep object assign. Values in the right-side source are ignored if pred
   * returns false. Mutates target object. Not exported.
   */
  const deepAssign = (tgt: any, src: any) => {
    for (const k in src) {
      const v: unknown = src[k]
      if (!pred(v)) continue
      const ov: unknown = tgt[k]
      tgt[k] =
        !isObjectType(v) ? v
        : !isObjectType(ov) ? clone(v)
        : isPlainObject(ov) && isPlainObject(v) ? deepAssign(ov, v)
        : isPlainArray(ov) && isPlainArray(v) ?
          arrays === 'merge' ? deepAssign(ov, v)
          : arrays === 'concat' ? (clone(v).forEach(vv => ov.push(vv)), ov)
          : clone(v)
        : clone(v)
    }
    return tgt
  }

  /**
   * Deep object merge. Undefined values in the right-side sources are ignored
   * rather than merged. Returns a new object, deeply cloned from all sources.
   */
  const deepMerge = <T extends object>(
    def: T,
    opts?: Partial<Options>,
  ): ((...srcs: Array<PartialDeep<T> | Falsish>) => T) =>
    opts ?
      mergeFns({...options, ...opts}).deepMerge(def)
    : (...srcs) => {
        const tgt = clone(def)
        for (const src of srcs) {
          if (src) deepAssign(tgt, src)
        }
        return tgt
      }

  /**
   * Deep object clone.
   */
  const clone = <T>(input: T, opts?: Partial<Options>): T => {
    if (opts) return mergeFns({...options, ...opts}).clone(input)
    const _clone = <T>(input: T): T => {
      if (!isObjectType(input)) return input
      if (isCloneable(input)) return input.clone()
      if (isPlainDate(input)) return new Date(input.getTime()) as T
      if (isPlainArray(input)) {
        const output = Array(input.length)
        for (const k in input) output[k] = _clone(input[k])
        return output as T
      }
      if (isPlainObject(input)) {
        const output: any = {}
        for (const k in input) output[k] = _clone(input[k])
        return output
      }
      return unk(input)
    }
    return _clone(input)
  }

  return {clone, deepMerge}
}

function isCloneable(x: unknown): x is {clone: () => any} {
  return typeof (x as any)?.clone === 'function'
}

function isPlainDate(x: unknown): x is Date {
  return (x as any)?.constructor === Date
}

function isPlainArray(x: unknown): x is Array<unknown> {
  return (x as any)?.constructor === Array
}

function className(x: object) {
  return Object.prototype.toString.call(x).slice(8, -1)
}

export const {deepMerge, clone} = mergeFns()

import {createTheming} from '@callstack/react-theme-provider'
import {getBorders} from './borders'
import {Branding, getBranding} from './branding'
import {getColors} from './colors'
import {getFakeCovers} from './fakeCovers'
import {getFonts} from './fonts'

export const createTheme = (_branding?: Branding) => {
  const branding = getBranding(_branding)
  const fonts = getFonts(branding.fonts)
  const colors = getColors(branding.colors)
  const borders = getBorders(colors.colors.borderColor)
  const fakeCovers = getFakeCovers(colors)
  return {...fonts, ...colors, ...borders, ...fakeCovers}
}
export const defaultTheme = createTheme()
export type Theme = typeof defaultTheme
export const {
  ThemeProvider,
  withTheme: _withTheme,
  useTheme,
} = createTheming(defaultTheme)
export const withTheme = _withTheme as <C>(comp: C) => C

// for styled-components theme compat
export * from './branding'
export * from './fonts'

/**
 * Quickstart feature flags aka Hacks.
 *
 * On the server, these are set in the design parameter QuickstartHacks, which
 * should be a whatever-separated list of flags, for example:
 *
 * QuickstartHacks: foo,nobar,baz=qux
 *
 * enables the "foo" feature flag, disables the "bar" feature flag, and sets the
 * "baz" feature flag to the value "qux"
 */

import {parseBool} from 'tizra'
import {logger} from 'tizra/log'
import * as R from 'rambdax'

const log = logger('quickstart/hacks')

export const defaultHacks = {
  alert: true,
  boost: false,
  br: true,
  checkoutApi: false,
  clockSkewTolerance: 30,
  coverFallback: false,
  reloadBehavior: 'bust' as 'bust' | 'invalidate' | 'none',
  lexical: false,
  nestedNavUi: true,
  persist: true,
  post: false,
  reloadOnApiError: true,
  // Tizra sites and data are relatively static, so we generally reuse query
  // results for five minutes before marking them stale. At that point they'll
  // be revalidated by the next component mount (usually the next page load).
  staleTime: 5 * 60,
} satisfies {[k: string]: boolean | number | string}

export type Hacks = typeof defaultHacks

export type Hack = keyof Hacks

export type HacksFromServer = string[]

const lowerToCanonical = Object.fromEntries(
  Object.keys(defaultHacks).map(k => [k.toLowerCase(), k]),
) as {[k in Hack as Lowercase<k>]: k}

const canonicalHack = (k: string): Hack | undefined =>
  lowerToCanonical[k.toLowerCase().replace(/[\W_]/g, '') as Lowercase<Hack>]

/**
 * Convert from string array to Hacks.
 */
export function fromServer(xs?: HacksFromServer, fallback?: true): Hacks
export function fromServer(
  xs: HacksFromServer | undefined,
  fallback: false,
): Partial<Hacks>
export function fromServer(xs: HacksFromServer = [], fallback = true) {
  return xs.reduce(
    (hacks, s) => {
      const [k, v] = R.piped(
        s.split('=') as [string, string | undefined],
        ([k, v]) => {
          const ck = canonicalHack(k)
          if (ck) return [ck, v]
          if (v === undefined && k.startsWith('no')) {
            const nok = canonicalHack(k.substring(2))
            if (nok && typeof defaultHacks[nok] === 'boolean') {
              return [nok, 'false']
            }
          }
          return [] as unknown as [Hack] // white lie
        },
      )
      switch (typeof defaultHacks[k]) {
        case 'boolean':
          hacks[k] = parseBool(v) ?? true
          break
        case 'number': {
          const n = parseInt(v!)
          if (n || n === 0) hacks[k] = n
          else log.warn('invalid number value', s)
          break
        }
        case 'string': {
          if (typeof v === 'string') hacks[k] = v
          else log.warn('invalid string value', s)
          break
        }
        default:
          // We used to warn here, but hacks come and go, and some are
          // server-only, so it just makes a lot of noise when sites have hacks
          // that we don't grok.
          log.debug?.('unknown hack', s)
      }
      return hacks
    },
    fallback ?
      ({...defaultHacks} as {[k in Hack]: any})
    : ({} as {[k in Hack]?: any}),
  )
}

export function toServer(hacks: Partial<Hacks>) {
  return Object.entries(hacks).map(([k, v]) =>
    v === true ? k
    : v === false ? `no${k[0].toUpperCase()}${k.substring(1)}`
    : `${k}=${v}`,
  )
}

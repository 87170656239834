import {QueryClient} from '@tanstack/react-query'
import {BlockContext} from 'quickstart/types'
import {deepMerge} from '../deepMerge'
import {IS_SSR} from '../globals'
import {defaultOptions} from './private'
import {defaultHacks} from 'quickstart/hacks'

export interface GetQueryClientProps {
  context: Pick<BlockContext, 'hacks'> | false
}

export const getQueryClient = ({context}: GetQueryClientProps) => {
  // N.B. This must be Infinity when running on the server, otherwise react-query
  // attempts to call the non-existent setTimeout.
  const staleTime =
    IS_SSR ? Infinity : (
      (!context ?
        defaultHacks.staleTime
      : context.hacks.staleTime ?? defaultHacks.staleTime) * 1000
    )
  const queryClient = new QueryClient({
    defaultOptions: deepMerge(defaultOptions)({
      queries: {staleTime},
    }),
  })
  return queryClient
}

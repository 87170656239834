import escape from 'escape-string-regexp'
export {escape}

/**
 * Compile a regular expression, handling the u and x flags.
 */
export function compile(patt: string, flags = '') {
  // Eliminate the 'u' flag in non-supporting browsers (IE 11).
  if (!('unicode' in RegExp.prototype)) {
    flags = flags.replace(/u/g, '')
  }

  if (flags.includes('x')) {
    flags = flags.replace(/x/g, '')

    // Remove comments. This is slightly different from spec because it treats
    // # as the comment character if it is preceded by whitespace, even if it's
    // inside a character class.
    patt = patt.replace(/\s#.*$/gm, '')

    // Remove internal whitespace, unless backslack-escaped. This uses
    // a capturing forward lookahead to avoid catastrophic backtracking.
    // https://wanago.io/2019/09/23/regex-course-part-four-avoiding-catastrophic-backtracking-using-lookahead/
    patt = patt.replace(/(?:(?=(\\))(?:\1( )|(\1.))|\s)/gs, '$2$3')
  }

  return new RegExp(patt, flags)
}

/**
 * Compile a regular expression from a tagged template literal.
 */
export function tag(
  flags?: string,
): (strings: TemplateStringsArray, ...values: any[]) => RegExp
export function tag(strings: TemplateStringsArray, ...values: any[]): RegExp
export function tag(...args: any) {
  let flags = args[0] || ''
  if (Array.isArray(flags)) {
    return tag()(...(args as [TemplateStringsArray, ...any[]]))
  }
  return (strings: TemplateStringsArray, ...values: any[]) => {
    let patt = strings.raw[0]
    values.forEach((v, i) => {
      patt += (v?.source ?? v ?? '') + strings.raw[i + 1]
    })
    return compile(patt, flags)
  }
}

/**
 * Insert a back-reference in a tagged template literal, e.g.
 *
 *   regexp.tag('gisux')`(x|y)${regex.backref(1)}`
 *
 * If we use \1 directly in the tagged template literal, then strict mode
 * complains about octal escapes. If we use \\1 then strings.raw will return
 * both backslashes. There's really no way to make this behave without a helper
 * function; even the XRegExp library (which inspired our regexp.tag) requires
 * doubled backslashes, see note at https://xregexp.com/api/#XRegExp
 */
export function backref(s: number | string) {
  return '\\' + s
}
